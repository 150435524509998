import * as SendBird from 'sendbird'
import { APP_ID } from './sbConstants'
import { SET_LOADING_CHANNEL, SET_CHANNEL_LIST } from './types/sendbird'

export default {
  namespaced: true,
  state: {
    sbChannelList: [],
    currentChannel: {},
    isLoadingChannel: false
  },
  getters: {
    getContactWith: state => userId => {
      if (!state.currentChannel || !state.currentChannel.members) return {}
      return state.currentChannel.members.find(v => v.userId !== userId)
    }
  },
  mutations: {
    [SET_CHANNEL_LIST](state, payload) {
      state['sbChannelList'] = payload
    },
    sbSetCurChannel(state, payload) {
      state['currentChannel'] = payload
    },
    sbUpdateCount(state, payload) {
      for (let i = 0; i < state['sbChannelList'].length; i++) {
        if (state['sbChannelList'][i].url === payload.url) {
          state['sbChannelList'][i] = { ...state['sbChannelList'][i], ...payload }
          break
        }
      }
    },
    [SET_LOADING_CHANNEL](state, payload) {
      state.isLoadingChannel = payload
    },
    resetChannel(state) {
      state['sbChannelList'] = []
      state['currentChannel'] = {}
    }
  },
  actions: {
    sbGetGroupChannelList({ commit }) {
      commit(SET_LOADING_CHANNEL, true)
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })

      const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery()
      channelListQuery.includeEmpty = true
      channelListQuery.order = 'latest_last_message'
      channelListQuery.limit = 100 // The value of pagination limit could be set up to 100.

      if (channelListQuery.hasNext) {
        channelListQuery.next((channelList, error) => {
          if (error) {
            return
          }

          commit(SET_CHANNEL_LIST, JSON.parse(JSON.stringify(channelList)))
          commit(SET_LOADING_CHANNEL, false)
        })
      }
    },
    sbSelectChannel({ commit }, payload) {
      commit('sbSetCurChannel', payload)
    },
    async sbGetGroupChannel(channelUrl) {
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })

      const channel = await sb.GroupChannel.getChannel(channelUrl)
      // TODO:

      return channel
    },
    sbResetChannel({ commit }) {
      commit('resetChannel')
    },
    getChannelByUserID({ state }, id) {
      const channels = state.sbChannelList
      const channel = channels.find(({ members }) => {
        return members.find(member => parseInt(member.userId) == id)
      })
      return channel
    }
  }
}
