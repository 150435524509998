<template>
  <div class="viewer-container" :class="{ hide: !isShow }">
    <div class="close-btn">
      <button type="button" class="close" aria-label="Close" @click="close">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="current-image">
      <img :src="currentImage" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    currentImage: null,
    isShow: false
  }),
  computed: {
    images() {
      return this.$store.state.images
    }
  },
  watch: {
    images() {
      if (this.images.length === 0) {
        this.currentImage = null
      } else {
        this.currentImage = this.images[0]
        this.isShow = true
      }
    }
  },
  methods: {
    close() {
      this.$store.commit('setImages', [])
      this.currentImage = null
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.close-btn {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 8px;
  right: 8px;
  margin-left: auto;
  button {
    outline: none;
  }
}

.viewer-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 9999;
  .current-image {
    width: 80vw;
    height: 80vh;
    margin: auto;
    position: relative;
    display: flex;
    margin-bottom: auto;
    img {
      display: block;
      margin: auto;
      width: 55%;
    }
    @media only screen and (max-width: 600px) {
      img {
        display: block;
        margin: auto;
        width: 80%;
      }
    }
  }

  &.hide {
    display: none;
  }
}
</style>
