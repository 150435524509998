<template>
  <div>
    <h5 class="text-center" v-if="!hideHeader">{{ $t('pages.policy.heading') }}</h5>
    <h6>{{ $t('pages.policy.paragraph_1_heading') }}</h6>
    <p>
      {{ $t('pages.policy.paragraph_1_content_1') }}
    </p>
    <p>
      {{ $t('pages.policy.paragraph_1_content_2') }}
    </p>
    <p>
      {{ $t('pages.policy.paragraph_1_content_3') }}
    </p>

    <h6>{{ $t('pages.policy.paragraph_2_heading') }}</h6>
    <p v-html="$t('pages.policy.paragraph_2_content_1')"></p>
    <p v-html="$t('pages.policy.paragraph_2_content_2')"></p>
    <p v-html="$t('pages.policy.paragraph_2_content_3')"></p>

    <h6>{{ $t('pages.policy.paragraph_3_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_3_content_1') }}</p>
    <div v-html="$t('pages.policy.paragraph_3_content_1-1')"></div>
    <p>{{ $t('pages.policy.paragraph_3_content_2') }}</p>
    <p>{{ $t('pages.policy.paragraph_3_content_3') }}</p>
    <p>{{ $t('pages.policy.paragraph_3_content_4') }}</p>
    <p v-html="$t('pages.policy.paragraph_3_content_5')"></p>
    <p v-html="$t('pages.policy.paragraph_3_content_5-1')"></p>
    <p v-html="$t('pages.policy.paragraph_3_content_5-2')"></p>
    <p v-html="$t('pages.policy.paragraph_3_content_5-3')"></p>

    <h6>{{ $t('pages.policy.paragraph_4_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_4_content_1') }}</p>

    <h6>{{ $t('pages.policy.paragraph_5_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_5_content_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_5_content_2') }}</p>

    <h6>{{ $t('pages.policy.paragraph_6_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_6_content_1') }}</p>

    <h6>{{ $t('pages.policy.paragraph_7_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_7_content_1') }}</p>

    <h6>{{ $t('pages.policy.paragraph_8_heading') }}</h6>
    <p v-html="$t('pages.policy.paragraph_8_content_1')"></p>
    <p>{{ $t('pages.policy.paragraph_8_content_2') }}</p>

    <h6>{{ $t('pages.policy.paragraph_9_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_9_content_1') }}</p>

    <h6>{{ $t('pages.policy.paragraph_10_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_10_content_1') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_heading_2') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_heading_3') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_1_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_10_content_1_2') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_2_1') }}</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('pages.policy.paragraph_10_content_2_2') }}</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('pages.policy.paragraph_10_content_2_3') }}</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('pages.policy.paragraph_10_content_2_4') }}</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('pages.policy.paragraph_10_content_2_5') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_3_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_10_content_3_2') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_4_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_10_content_4_2') }}</p>
    <p class="none-indent">{{ $t('pages.policy.paragraph_10_content_5_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_10_content_5_2') }}</p>

    <h6>{{ $t('pages.policy.paragraph_11_heading') }}</h6>
    <p v-html="$t('pages.policy.paragraph_11_content_1')"></p>
    <p>{{ $t('pages.policy.paragraph_11_content_2') }}</p>
    <p>{{ $t('pages.policy.paragraph_11_content_3') }}</p>
    <p>{{ $t('pages.policy.paragraph_11_content_4') }}</p>

    <h6>{{ $t('pages.policy.paragraph_12_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_12_content_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_12_content_2') }}</p>

    <h6>{{ $t('pages.policy.paragraph_13_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_13_content_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_13_content_2') }}</p>
    <p>{{ $t('pages.policy.paragraph_13_content_3') }}</p>

    <h6>{{ $t('pages.policy.paragraph_14_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_14_content_1') }}</p>

    <h6>{{ $t('pages.policy.paragraph_15_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_15_content_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_15_content_2') }}</p>
    <p>{{ $t('pages.policy.paragraph_15_content_3') }}</p>
    <p>{{ $t('pages.policy.paragraph_15_content_4') }}</p>

    <h6>{{ $t('pages.policy.paragraph_16_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_16_content_1') }}</p>
    <p>{{ $t('pages.policy.paragraph_16_content_2') }}</p>
    <p v-html="$t('pages.policy.paragraph_16_content_3')"></p>
    <p>{{ $t('pages.policy.paragraph_16_content_4') }}</p>
    <p>{{ $t('pages.policy.paragraph_16_content_5') }}</p>
    <p>{{ $t('pages.policy.paragraph_16_content_6') }}</p>

    <h6>{{ $t('pages.policy.paragraph_17_heading') }}</h6>
    <p>{{ $t('pages.policy.paragraph_17_content_1') }}</p>

    <p class="text-right">{{ $t('pages.policy.lastupdate') }}</p>
  </div>
</template>

<style lang="scss" scoped>
p {
  line-height: 1.75em;
  text-indent: 3em;
  font-weight: 400 !important;
}

.none-indent {
  text-indent: 0 !important;
}
</style>

<script>
export default {
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
