<template>
  <div class="card maid-available-detail">
    <p>
      {{ $t('form_label.available_time_on') }}
      <span class="text-primary">{{ selectedDate | formatDate(currentDateFormat) }}</span>
    </p>
    <ul v-if="maidCalendars.length > 0">
      <li v-for="(time, index) in maidCalendars" :key="`li-${index}`" class="text-success">
        {{ time.start_datetime | formatDate('H.mm') }} -
        {{ time.end_datetime | formatDate('H.mm') }}
      </li>
    </ul>
    <!-- <div class="underline my-2" v-if="hasBookedTimeSlots"></div> -->
    <!-- <ul v-if="hasBookedTimeSlots">
      <li v-for="(time, index) in maidBookedTimeSlots" :key="`li-${index}`" class="text-danger">
        {{ time.start_datetime | formatDate('HH.mm') }} -
        {{ time.end_datetime | formatDate('HH.mm') }}
      </li>
    </ul> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const CalendarStore = createNamespacedHelpers('calendar')
@Component({
  props: ['selectedDate', 'maidCalendars'],
  computed: {
    ...CalendarStore.mapGetters(['maidBookedTimeSlotsForSelectedDate'])
  }
})
export default class CalendarDetail extends Vue {
  get currentDateFormat() {
    return this.$i18n.locale === 'th' ? 'ddd D MMM' : 'MMM D, ddd'
  }

  get hasBookedTimeSlots() {
    return this.maidBookedTimeSlots.length > 0
  }

  get maidBookedTimeSlots() {
    return this.maidBookedTimeSlotsForSelectedDate
  }
}
</script>
