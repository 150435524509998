const SIMULATE_DEV = false;
const SIMULATE_UAT = false;
const SIMULATE_PROD = false;

// Backend URL
export const BASE_URL = "https://apidev.bluuu.co";
export const UAT_URL = "https://apistaging.bluuu.co";
export const PROD_URL = "https://api.bluuu.co";

// Firebase config
// export const FIREBASE_CONFIG_DEV = {
//   apiKey: "AIzaSyDRjk1lvxOi_3OuiMhc1_ifrhwuOmAO2wc",
//   authDomain: "bluuu-dev.firebaseapp.com",
//   databaseURL: "https://bluuu-dev.firebaseio.com",
//   projectId: "bluuu-dev",
//   storageBucket: "bluuu-dev.appspot.com",
//   messagingSenderId: "376277852768",
//   appId: "1:376277852768:web:2886de49c5dfb8a0525905",
// };
export const FIREBASE_CONFIG_DEV = {
  apiKey: "AIzaSyDILV9ncz0ovQMBDlCPq8vRvKYSn1TgSdM",
  authDomain: "bluuu-uat.firebaseapp.com",
  databaseURL: "https://bluuu-uat.firebaseio.com",
  projectId: "bluuu-uat",
  storageBucket: "bluuu-uat.appspot.com",
  messagingSenderId: "771887845236",
  appId: "1:771887845236:web:15ac3e2811d8497b60e621",
};

export const FIREBASE_CONFIG_UAT = {
  apiKey: "AIzaSyDILV9ncz0ovQMBDlCPq8vRvKYSn1TgSdM",
  authDomain: "bluuu-uat.firebaseapp.com",
  databaseURL: "https://bluuu-uat.firebaseio.com",
  projectId: "bluuu-uat",
  storageBucket: "bluuu-uat.appspot.com",
  messagingSenderId: "771887845236",
  appId: "1:771887845236:web:15ac3e2811d8497b60e621",
};

export const FIREBASE_CONFIG_PRODUCTION = {
  apiKey: "AIzaSyCHM_rtIL92cKTHZbkkMbe6ENXUAk4uMtI",
  authDomain: "endless-radar-267815.firebaseapp.com",
  databaseURL: "https://endless-radar-267815.firebaseio.com",
  projectId: "endless-radar-267815",
  storageBucket: "endless-radar-267815.appspot.com",
  messagingSenderId: "876686123235",
  appId: "1:876686123235:web:0cf872d1f61f1448ec65d6",
};

// Sendbird
export const SENDBIRD_CONFIG_DEV = {
  appId: "AC287AD4-E4DC-4CD2-B9F5-9E5AB205C294",
  apiKey: "e4224011e0a78d002c63e296ae13dfd3c7e222f1",
};
export const SENDBIRD_CONFIG_UAT = {
  appId: "5F5454AC-DFED-43FB-90C7-8B3B232F66D1",
  apiKey: "cea96cd81321d3b5d1513e8a3a043d219e5b890e",
};
export const SENDBIRD_CONFIG_PRODUCTION = {
  appId: "CCD403D5-D6B3-4F46-A33D-4FAF26C816E9",
  apiKey: "a1aa2929bc8107b84cdabf274f8e14d4f77ef08e",
};

export const POSTBACKURL_DEV = `https://apidev.bluuu.co/v1.0/payment/callback`;
export const POSTBACKURL_UAT = `https://apistaging.bluuu.co/v1.0/payment/callback`;
export const POSTBACKURL_PROD = `https://api.bluuu.co/v1.0/payment/callback`;

export const ENV = Object.freeze({
  dev: "DEV",
  uat: "UAT",
  production: "PRODUCTION",
});

/**
 *
 * @param {String} host - window.location.host
 */
export const getEnvName = (host) => {
  // console.log("isdev", isDevEnv(host));
  if (SIMULATE_PROD) {
    return ENV.production;
  }
  if (SIMULATE_UAT) {
    return ENV.uat;
  }
  if (SIMULATE_DEV) {
    return ENV.dev;
  }
  if (isUATEnv(host)) {
    return ENV.uat;
  }
  if (isDevEnv(host)) {
    return ENV.dev;
  }
  return ENV.production;
};

// export const lineLiffID = (host) => {
//   if (isUATEnv(host)) {
//     console.log("1655557892-VZMnBojD");
//     return "1655557892-VZMnBojD";
//   } else {
//     return "1655557900-GBd2VYj9";
//   }
// };

export const isUATEnv = (host) => {
  const uatHosts = [
    'dev.bluuu.co',
    'uat.bluuu.co',
    'bluuu-demo.web.app',
    'localhost',
    '192.168.1.108',
    '192.168.1.110',
    '192.168.43.33',
    '192.168.0.101',
    '192.168.0.203',
    '192.168.1.105',
  ]
  return uatHosts.some((h) => host.includes(h))
}
/**
 *
 * @param {String} host - window.location.host
 */
export const isDevEnv = (host) => {
  const devHosts = ["houseofdev.tech", "bluuu-dev-front.web.app"];
  // console.log("devHost", devHosts);
  return devHosts.some((h) => host.includes(h));
};

/**
 *
 * @param {String} env
 */
export const getBackendURL = (env) => {
  const backendURL = {
    [ENV.dev]: BASE_URL,
    [ENV.uat]: UAT_URL,
    [ENV.production]: PROD_URL,
  };
  return backendURL[env];
};

export const getFirebaseConfig = (env) => {
  const firebaseConfig = {
    [ENV.dev]: FIREBASE_CONFIG_DEV,
    [ENV.uat]: FIREBASE_CONFIG_UAT,
    [ENV.production]: FIREBASE_CONFIG_PRODUCTION,
  };
  console.log("firebaseConfig:", env);
  return firebaseConfig[env];
};

export const getSendbirdConfig = (env) => {
  const sendbirdConfig = {
    [ENV.dev]: SENDBIRD_CONFIG_DEV,
    [ENV.uat]: SENDBIRD_CONFIG_UAT,
    [ENV.production]: SENDBIRD_CONFIG_PRODUCTION,
  };
  return sendbirdConfig[env];
};

export const getPaySolutionCallbackURL = (env) => {
  const callback = {
    [ENV.dev]: POSTBACKURL_DEV,
    [ENV.uat]: POSTBACKURL_UAT,
    [ENV.production]: POSTBACKURL_PROD,
  };
  return callback[env];
};
