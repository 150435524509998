import moment from 'moment'
import momentTZ from 'moment-timezone'
import ApiClient from '../services'
import { isToday, getDisabledDateFromTimeSlot } from '../utils'
import { mergeOverlapTimeSlot, isTimeSlotInServiceTime } from '../helpers/calendar'

/**
 * Store สำหรับเก็บ maid time slot
 * maidCalendar: {
 *  2019: {
 *       1: [] // มีทั้งว่างและไม่ว่าง
 *    }
 * }
 */

const initState = () => ({
  maidCalendar: {}, // Maid's calendar all month
  maidCalendarTotalHours: [],
  currentSelectedOnCalendar: null, // ISOString ของวันที่เลือกอยู่บนปฏิทิน (กรอบสีน้ำเงิน)
  id: undefined, // maid's id
  isFetchingCalendar: false,
  isInitializeTimeSlot: false,
  currentUpdatingTimeSlotNumber: 0, // number ของ time slot ที่กำลังอัพเดตอยู่,
  renderCalendarCount: 0,
  currentBookingId: null
})

export default {
  namespaced: true,
  state: { ...initState() },
  mutations: {
    addCaldendar(state, payload) {
      if (!state.maidCalendar[payload.year]) {
        state.maidCalendar[payload.year] = {}
      }
      state.maidCalendar[payload.year] = {
        ...state.maidCalendar[payload.year],
        [payload.month]: payload.calendar
      }
    },
    addCalendarTotalHours(state, payload) {
      state.maidCalendarTotalHours = [...state.maidCalendarTotalHours, ...payload]
    },
    resetMaidCalendar(state) {
      state.maidCalendar = {}
      state.maidCalendarTotalHours = []
      // state.currentSelectedOnCalendar = null
      state.isFetchingCalendar = false
      state.id = undefined
    },
    setMaidID(state, payload) {
      state.id = payload
    },
    setIsFetchingCalendar(state, payload) {
      state.isFetchingCalendar = payload
    },
    setIsInitializeTimeSlot(state, payload) {
      state.isInitializeTimeSlot = payload
    },
    setCurrentUpdatingTimeSlotNumber(state, payload) {
      state.currentUpdatingTimeSlotNumber = payload
    },
    setCurrentSelectedOnCalendar(state, payload) {
      state.currentSelectedOnCalendar = payload
    },
    incrementRenderCount(state) {
      state.renderCalendarCount += 1
    },
    setCurrentBookingId(state, id) {
      state.currentBookingId = id
    }
  },
  actions: {
    async isAvailableTimeSlot(
      { state, dispatch },
      { start_datetime, end_datetime, booking_id, status }
    ) {
      if (!start_datetime) return null
      const startDate = moment(start_datetime)
      const endDate = moment(end_datetime)
      const month = startDate.get('month') + 1
      const year = startDate.get('year')
      const isCalendarExist = await dispatch('isCalendarExist', {
        month,
        year
      })
      if (!isCalendarExist) return false
      const maidCalendar = state.maidCalendar[year][month]

      let matchedDate
      // console.log("status", status);
      // เช็คว่าแม่บ้านว่างไหม
      if (booking_id && status === 'APPROVED') {
        matchedDate = maidCalendar.find(d => {
          return (
            d.start_datetime === start_datetime &&
            d.end_datetime === end_datetime &&
            ['BOOKED', 'RESERVED'].includes(d.type) &&
            d.booking.id == state.currentBookingId
          )
        }) // return object if maid is free, return undefined if maid is busy
      } else {
        matchedDate = maidCalendar.find(d => {
          const maidStartDate = moment(d.start_datetime)
          const maidEndDate = moment(d.end_datetime)
          const isOwnCustomerBooking = d.booking
            ? ['BOOKED', 'RESERVED'].includes(d.type) && d.booking.id === state.currentBookingId
            : false
          return (
            startDate >= maidStartDate &&
            endDate <= maidEndDate &&
            (d.type === 'FREE' || isOwnCustomerBooking)
          )
        }) // return object if maid is free, return undefined if maid is busy
      }
      return Boolean(matchedDate)
    },
    updateMaidCalendarByMonth({ state, commit }, payload) {
      if (state.id !== payload.id) {
        commit('resetMaidCalendar')
        commit('setMaidID', payload.id)
      }
      commit('addCaldendar', payload)
    },
    isCalendarExist({ state }, { month, year }) {
      if (!state.maidCalendar.hasOwnProperty(year)) return false // Have no year
      const yearCalendar = { ...state.maidCalendar[year] }
      return yearCalendar.hasOwnProperty(month)
    },
    async fetchMaidCalendarTotalHours({ commit }, { id, month, year }) {
      // console.log("yes");
      try {
        // const result = await ApiClient.maidCalendarBookingTotalHours({
        //   id,
        //   month,
        //   year,
        // });
        // const result = await ApiClient.maidCalendarAllMonthPublic({ id, month, year })
        //commit("addCalendarTotalHours", result.data); // [ {date: "2020-02-01", total_hours: "10"}, ...]
      } catch (e) {
        console.log(e.response)
      }
    },
    async fetchMaidCalendarAllMonth({ dispatch, commit }, { id, month, year, isMe = false }) {
      commit('setIsFetchingCalendar', true)
      try {
        const result = await ApiClient.maidCalendarAllMonth({ id, month, year }, isMe)
        dispatch('updateMaidCalendarByMonth', {
          month,
          year,
          calendar: result.data,
          id
        })
        dispatch('calculateTotalHoursFromTimeSlots', result.data)
        commit('setIsFetchingCalendar', false)
        return result.data
      } catch (e) {
        console.log(e.response)
      }
      commit('setIsFetchingCalendar', false)
    },
    async fetchMaidCalendarAllMonthPublic({ dispatch }, { id, month, year }) {
   
      try {
        const result = await ApiClient.maidCalendarAllMonthPublic({
          id,
          month,
          year
        })
 

        dispatch('updateMaidCalendarByMonth', {
          month,
          year,
          calendar: result.data,
          id
        })

        dispatch('calculateTotalHoursFromTimeSlots', result.data)
        return result.data
      } catch (e) {
        console.log(e.response)
      }
    },
    calculateTotalHoursFromTimeSlots({ commit }, payload) {
      let totalHours = {}
      const bookedTimeSlots = payload.filter(timeslot => timeslot.type === 'BOOKED')
      bookedTimeSlots.forEach(timeslot => {
        const duration = momentTZ(timeslot.end_datetime).diff(
          momentTZ(timeslot.start_datetime),
          'hour'
        )
        const date = momentTZ(timeslot.end_datetime).format('YYYY-MM-DD')
        if (!totalHours.hasOwnProperty(date)) {
          totalHours[date] = {
            date,
            total_hours: duration
          }
        } else {
          totalHours[date] = {
            date,
            total_hours: totalHours[date].total_hours + duration
          }
        }
      })
      commit('addCalendarTotalHours', Object.values(totalHours))
    }
  },
  getters: {
    calendarTotalHours(state) {
      return state.maidCalendarTotalHours.filter(({ date }) => momentTZ(date) > momentTZ())
    },
    baseDisabledHighlight() {
      const offset =
        -(new Date().getTimezoneOffset() / 60) < 0 ? 0 : -(new Date().getTimezoneOffset() / 60)
      const firstDateOfMonth = moment()
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
      const calendarAttributes = []
      while (firstDateOfMonth.isSameOrBefore(moment(new Date(...momentTZ().toArray())), 'day')) {
        const tempTime = firstDateOfMonth.clone()
        calendarAttributes.push({
          key: 'busy',
          highlight: 'gray',
          content: isToday(tempTime.add(offset, 'hour').toISOString()) ? 'blue' : 'gray',
          dates: new Date(firstDateOfMonth.toISOString()),
          start_datetime: firstDateOfMonth.toISOString()
        })
        firstDateOfMonth.add(1, 'day')
      }
      // ถ้าเวลา ณ ปัจจุบันเลย 19.00 => จะไม่มี Options สำหรับวันพรุ่งนี้ (Disable ไปเลย)
      const now = momentTZ()
      if (now.hour() > 19) {
        calendarAttributes.push({
          key: 'busy',
          highlight: 'gray',
          content: 'gray',
          dates: new Date(firstDateOfMonth.toISOString()),
          start_datetime: firstDateOfMonth.toISOString()
        })
      }
      return calendarAttributes
    },
    maidCalendarCurrentMonthSelect(state) {
      if (!state.currentSelectedOnCalendar) return [] // ไม่มีวันที่เลือกอยู่บนปฏิทิน
      const selectDate = moment(state.currentSelectedOnCalendar)
      const year = selectDate.year()
      const month = selectDate.month()
      return state.maidCalendar[year][month + 1]
    }, // Return array - timeslot ทั้งหมดของเดือนที่เลือกอยู่บนปฏิิทิน
    maidAvailableTimeSlots(state) {
      try {
        if (!state.currentSelectedOnCalendar || !state.id) return [] // ไม่มีวันที่เลือกอยู่บนปฏิทิน หรือ ไม่มีแม่บ้านที่กำลังเลือกอยู่
        const selectDate = momentTZ(state.currentSelectedOnCalendar)
          .hour(0)
          .minute(0)

        if (selectDate <= moment()) return []

        const year = selectDate.year()
        const month = selectDate.month()
        let maidCalendar = state.maidCalendar[year] && state.maidCalendar[year][month + 1]

        if (!maidCalendar) return []
        const timeSlots = maidCalendar
          .filter(slot => {
            const isFree = slot.type === 'FREE'
            const isOwnCustomerBooking =
              (slot.type === 'BOOKED' || slot.type === 'RESERVED') &&
              slot.booking.id === state.currentBookingId
            return (
              (isFree || isOwnCustomerBooking) &&
              momentTZ(slot.start_datetime).isSame(selectDate, 'date') &&
              isTimeSlotInServiceTime(slot)
            )
          }) // เอาช่วงเวลาที่แม่บ้านว่าง + เป็นวันเดียวกันกับที่เลือกอยู่บนปฏิทิน + ช่วงเวลาที่เป็นของลูกค้า
          .sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime)) // เรียงวันจากน้อยไปมาก
        return mergeOverlapTimeSlot(timeSlots).filter(
          ({ start_datetime, end_datetime }) =>
            moment(end_datetime).diff(moment(start_datetime), 'hours') >= 2
        )
      } catch (e) {
        console.error(e)
        return []
      }
    }, // Return array - ช่วงเวลาที่แม่บ้านว่าง โดยอิงจาก เดือน/ปี ที่เลือกอยู่บนปฏิิทิน
    maidBookedTimeSlotsForSelectedDate(state, { maidCalendarCurrentMonthSelect }) {
      const selectDate = moment(state.currentSelectedOnCalendar)
      return maidCalendarCurrentMonthSelect.filter(
        slot => slot.type === 'BOOKED' && moment(slot.start_datetime).isSame(selectDate, 'date')
      )
    }, // Return array - ช่วงเวลาที่แม่บ้านทำงาน โดยอิงจากวันที่เลือกอยู่บนปฏิิทิน (Fix me - unused)
    maidReservedTimeSlotsForSelectedDate(state, { maidCalendarCurrentMonthSelect }) {
      const selectDate = moment(state.currentSelectedOnCalendar)
      return maidCalendarCurrentMonthSelect.filter(
        slot => slot.type === 'RESERVED' && moment(slot.start_datetime).isSame(selectDate, 'date')
      )
    }, // Return array - ช่วงเวลาที่แม่บ้านทำงาน โดยอิงจากวันที่เลือกอยู่บนปฏิิทิน (Fix me -unused)
    maidDisabledHighlight({ maidCalendar }) {
      let result = []
      for (const year in maidCalendar) {
        for (const month in maidCalendar[year]) {
          const calendar = maidCalendar[year][month]
          const notAvailableTimeSlot = getDisabledDateFromTimeSlot(calendar)
          result = [...result, ...notAvailableTimeSlot]
        }
      }
      return result
    } // Return array - ของวันที่จะเป็นสีเทาบนปฏิทิน
  }
}
