// booking constant
const ONE_TIME_MANUAL_SELECT = 'ONE_TIME_MANUAL_SELECT'
const ONE_TIME_AUTO_SELECT = 'ONE_TIME_AUTO_SELECT'
const MULTI_PACKAGE = 'MULTI_PACKAGE'
const ACCOMMODATION = [
  { text: 'condo_1_br', value: 'CONDO_1_BR' },
  { text: 'condo_2_br', value: 'CONDO_2_BR' },
  { text: 'condo_3_br', value: 'CONDO_3_BR' },
  { text: 'condo_4_br', value: 'CONDO_4_BR' },
  { text: 'house_1_story', value: 'HOUSE_1_STORY' },
  { text: 'house_2_stories', value: 'HOUSE_2_STORIES' },
  { text: 'house_3_stories', value: 'HOUSE_3_STORIES' },
  { text: 'office', value: 'OFFICE' },
  { text: 'others', value: 'OTHERS' }
]
const ADDITIONAL_INFO = [
  { text: 'i_have_a_cat', value: 'HAS_CAT' },
  { text: 'i_have_a_dog', value: 'HAS_DOG' }
]
const SERVICES = [
  { text: 'cleaning', value: 'CLEANING' },
  { text: 'cleaning_ironing', value: 'CLEANING_DRAPERY' },
  { text: 'ironing', value: 'DRAPERY' }
]

const EQUIPMENTS = [
  { text: 'already_have_equipment', value: 0, bring_equipment: false },
]

const DURATION = [
  { text: '2 Hrs', value: 2 },
  { text: '3 Hrs', value: 3 },
  { text: '4 Hrs', value: 4 },
  { text: '5 Hrs', value: 5 },
  { text: '6 Hrs', value: 6 },
  { text: '7 Hrs', value: 7 },
  { text: '8 Hrs', value: 8 }
]
const TIME_OPTIONS = [
  { text: '6:00', hour: 6, minute: 0 },
  { text: '6:30', hour: 6, minute: 30 },
  { text: '7:00', hour: 7, minute: 0 },
  { text: '7:30', hour: 7, minute: 30 },
  { text: '8:00', hour: 8, minute: 0 },
  { text: '8:30', hour: 8, minute: 30 },
  { text: '9:00', hour: 9, minute: 0 },
  { text: '9:30', hour: 9, minute: 30 },
  { text: '10:00', hour: 10, minute: 0 },
  { text: '10:30', hour: 10, minute: 30 },
  { text: '11:00', hour: 11, minute: 0 },
  { text: '11:30', hour: 11, minute: 30 },
  { text: '12:00', hour: 12, minute: 0 },
  { text: '12:30', hour: 12, minute: 30 },
  { text: '13:00', hour: 13, minute: 0 },
  { text: '13:30', hour: 13, minute: 30 },
  { text: '14:00', hour: 14, minute: 0 },
  { text: '14:30', hour: 14, minute: 30 },
  { text: '15:00', hour: 15, minute: 0 },
  { text: '15:30', hour: 15, minute: 30 },
  { text: '16:00', hour: 16, minute: 0 },
  { text: '16:30', hour: 16, minute: 30 },
  { text: '17:00', hour: 17, minute: 0 },
  { text: '17:30', hour: 17, minute: 30 },
  { text: '18:00', hour: 18, minute: 0 },
  { text: '18:30', hour: 18, minute: 30 },
  { text: '19:00', hour: 19, minute: 0 }
]

const MULTI_TIMES = [
  { text: '4 Times', value: 4 },
  { text: '8 Times', value: 8 },
  { text: '12 Times', value: 12 },
  { text: '16 Times', value: 16 },
  { text: '20 Times', value: 20 },
  { text: '24 Times', value: 24 },
  { text: '28 Times', value: 28 }
]

const MULTI_HOURS = [
  { text: '2 Hours', value: 2 },
  { text: '3 Hours', value: 3 },
  { text: '4 Hours', value: 4 },
  { text: '5 Hours', value: 5 },
  { text: '6 Hours', value: 6 },
  { text: '7 Hours', value: 7 },
  { text: '8 Hours', value: 8 }
]

const RECURRENT_DATES = [
  { text: 'Sunday', value: 0 },
  { text: 'Monday', value: 1 },
  { text: 'Tuesday', value: 2 },
  { text: 'Wednesday', value: 3 },
  { text: 'Thursday', value: 4 },
  { text: 'Friday', value: 5 },
  { text: 'Saturday', value: 6 }
]

// Fix me
const MOCK_MAID_USER = {
  id: 0,
  user: {
    first_name: 'No maid',
    id: 0,
    image_url: 'https://graph.facebook.com/112847403474995/picture?type=large',
    last_name: 'for this booking'
  },
  location_name: null,
  description: null,
  rating: 0,
  no_of_reviews: 1,
  total_hours: 0,
  level: 'No Level',
  rank: 0
}
const MOCK_MAID = {
  id: 0,
  user: {
    id: 0,
    first_name: 'Test Maid',
    last_name: '',
    email: 'test_maid@bluuu.co',
    fb_email: null,
    fb_id: '0',
    image_url: 'https://graph.facebook.com/112847403474995/picture?type=large',
    nick_name: null,
    phone: null,
    is_verified_phone: true,
    send_email_before_booking: true,
    send_email_when_remaining_credits: true,
    dont_mind_let_fb_friends: false,
    fb_token: '',
    role: 'MAID',
    maid_id: 0,
    admin_channel_url: null,
    create_datetime: '2019-07-14T07:20:34.000Z'
  },
  location_name: 'Bangkok, Thailand',
  description: null,
  rating: 5,
  no_of_reviews: 0,
  total_hours: 0,
  level: 'No Level',
  rank: 0,
  cover_images: [],
  criminal_checked: true,
  character_checked: true,
  facebook_verified: true
}
export default Object.freeze({
  ONE_TIME_MANUAL_SELECT,
  ONE_TIME_AUTO_SELECT,
  MULTI_PACKAGE,
  ACCOMMODATION,
  ADDITIONAL_INFO,
  SERVICES,
  DURATION,
  TIME_OPTIONS,
  MULTI_TIMES,
  MULTI_HOURS,
  RECURRENT_DATES,
  MOCK_MAID_USER,
  MOCK_MAID,
  EQUIPMENTS
})

export const STATUS = Object.freeze({
  approved: 'APPROVED',
  awaiting: 'AWAITING',
  updateAwaiting: 'UPDATE_AWAITING',
  updateApproved: 'UPDATE_APPROVED',
  canceled: 'CANCELED', // Not used
  canceledByCustomer: 'CANCELED_BY_CUSTOMER',
  canceledByMaid: 'CANCELED_BY_MAID',
  noAvailableMaid: 'NO_AVAILABLE_MAID'
})

export const ENABLE_APPLY_COUPON = false
export const ENABLE_FILTER_MAID_BY_USER_LOCATION = true
