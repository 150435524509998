import moment from 'moment'
import momentTZ from 'moment-timezone'
/**
 * TimeSlots should be sorted be use this function
 * @param {Array} timeSlots
 */
export const mergeOverlapTimeSlot = timeSlots => {
  const startTimer = new Date()
  if (!Array.isArray(timeSlots)) return []
  const timeSlotCount = timeSlots.length
  if (timeSlotCount <= 1) return timeSlots

  // สร้าง List โดยใส่ first element เป็นค่าเริ่มต้น
  const timeStack = [timeSlots[0]]

  for (let index = 1; index < timeSlotCount; index++) {
    // Top element in stack
    const topStack = timeStack[timeStack.length - 1]

    // Current element
    const currentTimeSlot = timeSlots[index]
    if (isTimeSlotOverlapping(topStack, currentTimeSlot)) {
      const timeslot = { ...topStack, end_datetime: currentTimeSlot.end_datetime }
      timeStack.pop()
      timeStack.push(timeslot)
    } else {
      timeStack.push(currentTimeSlot)
    }
  }
  const endTimer = new Date()
  console.log('Calculation time:', endTimer - startTimer)
  return timeStack
}

/**
 * t1 must be before t2
 * @param {Object} t1 : {start_datetime, end_datetime}
 * @param {Object} t2 : {start_datetime, end_datetime}
 */
export const isTimeSlotOverlapping = (t1, t2) => {
  const startT2 = moment(t2.start_datetime)
  const endT1 = moment(t1.end_datetime)
  const endT2 = moment(t2.end_datetime)
  return (endT1 >= startT2 && endT1 <= endT2) || (endT1 >= startT2 && endT1 >= endT2)
}

/** Return true if time slot is in service time (06.00-21.00) */
export const isTimeSlotInServiceTime = ({ start_datetime, end_datetime }) => {
  const startDateTime = momentTZ(start_datetime)
  const endDateTime = momentTZ(end_datetime)
  return (
    startDateTime.hour() >= 6 &&
    startDateTime.hour() <= 19 &&
    endDateTime.hour() >= 8 &&
    endDateTime.hour() <= 21 &&
    startDateTime.isSame(endDateTime, 'date')
  )
}

export const createMomentByRepeatDay = ({ repeatDay, hour, minute }) => {
  // const offset = -(new Date().getTimezoneOffset() / 60) <= -1 ? 1 : 0
  return momentTZ()
    .day(repeatDay)
    .hour(hour)
    .minute(minute)
    .second(0)
    .millisecond(0)
}

/**
 * คืนค่าอาร์เรย์ของวันเวลาที่เมดไม่สามารถรับงานได้ (ตารางเต็ม/วันนั้นไม่รับงาน)
 * @param {Array} timeSlotList
 */
export const getDisabledDateFromTimeSlot = timeSlotList => {
  // เรียงวันจากน้อยไปมาก
  const timeSlots = timeSlotList.sort(
    (a, b) => momentTZ(a.start_datetime) - momentTZ(b.start_datetime)
  )

  let upComingDays = {}

  // Filter เอาวันที่ไม่ว่าง + มีการจองแล้ว
  const unavailableTimeSlots = timeSlots.filter(
    d => ['BUSY', 'BOOKED', 'RESERVED'].includes(d.type) && momentTZ(d.start_datetime) > momentTZ()
  )

  // เพิ่มวันที่ไม่ว่างเข้า Object โดยที่ key = วันที่
  unavailableTimeSlots.forEach(d => {
    const date = momentTZ(d.start_datetime).date()
    upComingDays[date] = d
  })

  // Filter เอาวันที่ว่าง
  const availableTimeSlots = timeSlots.filter(
    slot =>
      slot.type === 'FREE' &&
      momentTZ(slot.start_datetime) > momentTZ() &&
      momentTZ(slot.end_datetime).diff(momentTZ(slot.start_datetime), 'second') >= 2 * 60 * 60
  )
  const mergedOverlapTimeSlots = mergeOverlapTimeSlot(availableTimeSlots)
  mergedOverlapTimeSlots.forEach(d => {
    const date = momentTZ(d.start_datetime).date()
    delete upComingDays[date] // ลบวันว่างออกจาก object เพื่อให้เหลือเฉพาะวันที่ไม่ว่าง
  })
  return Object.values(upComingDays)
}

export const getMaidCalendarTotalHoursUsingMaidTimeSlot = timeSlotList => {
  const totalHours = {}
  const maidCalendar = timeSlotList.filter(
    slot => slot.type === 'BOOKED' && moment(slot.start_datetime) > moment()
  )
  maidCalendar.forEach(({ start_datetime, end_datetime }) => {
    const startDateTime = moment(start_datetime)
    const endDateTime = moment(end_datetime)
    const totalHour = endDateTime.diff(startDateTime, 'hours')
    totalHours[startDateTime.date()] = totalHour
  })
}

/**
 *
 * @param {String} dateTime
 * @param {Array} timeSlots
 */
export const isDateInTimeSlots = (dateTime, timeSlots) => {
  return timeSlots.some(({ start_datetime }) =>
    momentTZ(start_datetime).isSame(momentTZ(dateTime), 'date')
  )
}

/**
 * @returns {Array} - return timeSlot1 ที่ถูก split โดย timeSlot2
 * @param {Array} timeSlot1
 * @param {Array} timeSlot2
 */
export const splitTimeSlots = (timeSlot1, timeSlot2) => {
  let dateTimeStack = []

  const timeSlots = sortTimeSlots([...timeSlot1, ...timeSlot2])
  const timeSlotsCount = timeSlots.length

  for (let i = 0; i < timeSlotsCount - 1; i++) {
    const t1 = timeSlots[i]
    const t2 = timeSlots[i + 1]

    if (t1.type === 'FREE' && !isTimeSlotOverlapping(t1, t2)) {
      // Not overlap
      dateTimeStack.push(t1)

      if (i + 1 === timeSlotsCount - 1) dateTimeStack.push(t2)
    } else if (isTimeSlotOverlapping(t1, t2) && t1.type === 'FREE') {
      const startT1 = moment(t1.start_datetime)
      const startT2 = moment(t2.start_datetime)
      const endT1 = moment(t1.end_datetime)
      const endT2 = moment(t2.end_datetime)
      // Note: t1 always lower than t2
      if (startT2 < endT1) {
        if (endT2 < endT1) {
          // t2 overlap at the middle of t1
          dateTimeStack.push({ ...t1, end_datetime: t2.start_datetime })
          dateTimeStack.push({
            ...t1,
            start_datetime: t2.end_datetime,
            end_datetime: t1.end_datetime
          })
        } else if (endT2.isSameOrAfter(endT1)) {
          // t2 overlap at the middle to end of t1
          dateTimeStack.push({ ...t1, end_datetime: t2.start_datetime })
        }
      }
    }
  }
  return dateTimeStack
}

/**
 * Sort array using start_datetime
 * @param {Array} timeslots
 */
export const sortTimeSlots = (timeslots, isAscending = true) => {
  const newArray = timeslots.sort((a, b) => {
    return moment(a.start_datetime) - moment(b.start_datetime)
  })

  return isAscending ? newArray : newArray.reverse()
}
