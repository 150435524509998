<template>
  <div>
    <div class="reviewer">
      <b-img :src="getReviewAvatar()" class="avatar" @error="(e) =>
        (e.target.src = require('../assets/images/preview-upload.png'))
      "
      ></b-img>
      <div class="detail">
        <span class="reviewer-name">
          {{ fullName }}
        </span>
        <span class="review-date">{{ start_datetime | formatDate('DD MMMM YYYY') }}</span>
        <star-rating
          :read-only="true"
          :rating="rating"
          :round-start-rating="false"
          :show-rating="false"
          :padding="2"
          :star-size="16"
        >
        </star-rating>
      </div>
      <!-- <div v-if="isUserReview" @click="$refs['review-maid'].show()" style="cursor: pointer">
        <i
          class="fas fa-times"
          style="font-size: 1em; line-height: 48px; color: rgb(255, 101, 101)"
        ></i>
      </div> -->
    </div>
    <div v-if="rating < 5" style="margin-bottom: 16px; font-size: 12px">
      <span>{{ choseLessThanRecommended }}</span>
    </div>
    <div v-if="description.length > 200 && !isSeemore">
      {{ description.slice(0, 200)
      }}<strong>
        ...
        <span @click="isSeemore = true" style="cursor: pointer; text-decoration: underline">{{
          $t('alias.see_more')
        }}</span>
      </strong>
    </div>
    <div v-else>
      {{ description }}
    </div>
    <b-modal
      v-if="isUserReview"
      id="review-maid-modal"
      ref="review-maid"
      size="sm"
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
    >
      <div class="review-maid-modal-container my-4 px-2">
        <!-- <div class="d-flex justify-content-center">
          <i class="fas fa-times" style="font-size: 5em; color: rgb(255, 101, 101)"></i>
        </div> -->
        <p
          class="text-center mt-3 mb-4"
          v-html="$t('rating.confirm_delete_review')"
        ></p>
        <b-row class="mt-3">
          <b-col cols="2"></b-col>
          <b-col cols="4">
            <b-button
              variant="dark"
              size="sm"
              class="action-btn"
              @click="$refs['review-maid'].hide()"
            >
              {{ $t('button.cancel') }}
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-button variant="danger" size="sm" class="action-btn" @click="deleteReview">
              <div>
                {{ $t('button.delete') }}
              </div>
            </b-button>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import get from 'lodash/get'
import StarRating from 'vue-star-rating'
import ApiClient from '../services'
export default {
  data() {
    return {
      isSeemore: false,
    }
  },
  components: { StarRating },
  props: {
    review: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['delete-review'],
  mounted() {
    // console.log(this.review)
  },
  methods: {
    getReviewAvatar() {
      return !get(this.review, 'anonymous', '')
        ? get(this.review, 'user.image_url', '')
        : require('../assets/images/preview-upload.png')
    },
    async deleteReview() {
      try {
        const response = await ApiClient.deleteReview({
          id: this.review.maid_id,
          review_id: this.review.id,
        })
      } catch (error) {
        console.log(error)
      }
      this.$emit('delete-review')
      this.$refs['review-maid'].hide()
    },
  },
  computed: {
    fullName: function () {
      return get(this.review, 'anonymous')
        ? this.$t('form_label.anonymous')
        : [get(this.review, 'user.first_name'), get(this.review, 'user.last_name')].join(' ')
    },
    start_datetime: function () {
      return get(this.review, 'booking_calendar.start_datetime')
    },
    description: function () {
      return get(this.review, 'description')
    },
    rating: function () {
      return get(this.review, 'rating')
    },
    choseLessThanRecommended() {
      if (!this.review.booking_calendar || !this.review.booking_calendar.booking) return ''
      const { accommodation, duration } = this.review.booking_calendar.booking

      if (!accommodation || !duration || accommodation === 'OTHERS') {
        return ''
      }

      let lessThan = ''

      const durationRecommendations = {
        CONDO_1_BR: { min: 3, max: 4 },
        CONDO_2_BR: { min: 4, max: 5 },
        CONDO_3_BR: { min: 5, max: 6 },
        CONDO_4_BR: { min: 6, max: 8 },
        HOUSE_1_STORY: { min: 3, max: 4 },
        HOUSE_2_STORIES: { min: 4, max: 6 },
        HOUSE_3_STORIES: { min: 6, max: 8 },
        OFFICE: { min: 3, max: 4 },
      }

      const recommendation = durationRecommendations[accommodation]

      if (recommendation) {
        if (duration < recommendation.min) {
          lessThan = `${recommendation.min - duration}-${recommendation.max - duration}`
        }
      }

      return lessThan === ''
        ? ''
        : `${this.$t(
            'rating.customer_booked_less_than_recommended_durations_1'
          )} ${lessThan} ${this.$t('rating.customer_booked_less_than_recommended_durations_2')}`
    },

    isUserReview() {
      return this.$store.state.user && get(this.review, 'user.id') === this.$store.state.user.id && this.rating < 5
    },
  },
}
</script>

<style></style>
