import * as SendBird from 'sendbird'
import sbChannel from './sbChannel'
import { APP_ID } from './sbConstants'
import { SET_MESSAGE_LIST, SET_MESSAGE_LOADING, RESET_MESSAGE, ADD_MESSAGE } from './types/sendbird'

export default {
  namespaced: true,
  state: {
    sbMessageList: [],
    isLoadingMessage: true,
    totalUnreadMessageCount: 0
  },
  getters: {},
  mutations: {
    [SET_MESSAGE_LIST](state, payload) {
      state.sbMessageList = payload
    },
    [ADD_MESSAGE](state, payload) {
      state.sbMessageList = [...state.sbMessageList, payload]
    },
    [SET_MESSAGE_LOADING](state, payload) {
      state.isLoadingMessage = payload
    },
    [RESET_MESSAGE](state) {
      state.sbMessageList = []
    },
    SET_TOTAL_UNREAD_MESSAGE_COUNT(state, payload) {
      state.totalUnreadMessageCount = payload
    }
  },
  actions: {
    async sbGetMessageList(context, channelUrl) {
      context.commit(SET_MESSAGE_LOADING, true)
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })

      const channelEvent = new sb.ChannelHandler()
      sbAddEventHandler(channelEvent, channelUrl, context)
      sb.addChannelHandler(channelUrl, channelEvent)

      try {
        const channel = await sbChannel.actions.sbGetGroupChannel(channelUrl)
        sbChannel.mutations.sbSetCurChannel(sbChannel.state, channel)

        const prevMessageListQuery = channel.createPreviousMessageListQuery()
        prevMessageListQuery.limit = 200
        prevMessageListQuery.load((messages, error) => {
          if (error) {
            return
          }

          context.commit(SET_MESSAGE_LIST, JSON.parse(JSON.stringify(messages)))
          context.commit(SET_MESSAGE_LOADING, false)
        })
      } catch (e) {
        return e
      }
    },
    async sbSendMessage({ commit }, data) {
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })

      const params = new sb.UserMessageParams()
      params.message = data.message

      try {
        const channel = await sbChannel.actions.sbGetGroupChannel(data.channelUrl)
        channel.sendUserMessage(params, (message, error) => {
          if (error) {
            return
          }

          commit(ADD_MESSAGE, message)
        })
      } catch (e) {
        return e
      }
    },
    async sbSendFile({ commit }, file) {
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })
      const params = new sb.FileMessageParams()
      params.file = file
      params.fileName = file.name
      params.fileSize = file.size
      params.thumbnailSizes = [{ maxWidth: 100, maxHeight: 100 }, { maxWidth: 200, maxHeight: 200 }]
      try {
        const channel = await sbChannel.actions.sbGetGroupChannel(file.channelUrl)
        channel.sendFileMessage(params, (fileMessage, error) => {
          if (error) {
            return
          }

          commit(ADD_MESSAGE, fileMessage)
        })
      } catch (e) {
        return e
      }
    },
    async sbMarkRead(context, channel) {
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })

      channel.markAsRead()

      var handler = new sb.ChannelHandler()
      handler.onReadReceiptUpdated = channell => {
        channel = channell
      }
      sb.addChannelHandler(channel, handler)
    },
    sbResetMessage({ commit }) {
      commit(RESET_MESSAGE)
    },
    getTotalUnreadMessageCount({ commit }) {
      let sb = SendBird.getInstance()
      if (!sb) sb = new SendBird({ appId: APP_ID })
      sb.getTotalUnreadMessageCount((count, err) => {
        if (err) {
          return
        }
        console.log(count)
        commit('SET_TOTAL_UNREAD_MESSAGE_COUNT', count)
      })
    }
  }
}

const sbAddEventHandler = (channelEvent, channelUrl, context) => {
  channelEvent.onMessageReceived = (channel, message) => {
    // Get current message list from store
    const msgs = JSON.parse(JSON.stringify(context.rootState.sbMessage.sbMessageList))
    sbChannel.mutations.sbUpdateCount(context.rootState.sbChannel, channel)
    if (msgs && msgs[0].channelUrl === message.channelUrl && channelUrl === message.channelUrl) {
      context.commit(ADD_MESSAGE, message)
    }
  }
}
