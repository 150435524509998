import * as SendBird from 'sendbird'
import { getSendbirdConfig } from './sbConstants'
import { SET_SB_USER, RESET_SB_USER } from './types/sendbird'

const { appId, apiKey } = getSendbirdConfig()

export default {
  namespaced: true,
  state: {
    sbUser: {}
  },
  getters: {},
  mutations: {
    [SET_SB_USER](state, payload) {
      state['sbUser'] = payload
    },
    [RESET_SB_USER](state) {
      state['sbUser'] = []
    }
  },
  actions: {
    sbConnect({ commit, rootState }) {
      var sb = new SendBird({ appId })

      const getUserId = () => {
        return rootState.user.id + ''
      }

      let userId = getUserId()

      if (!userId) userId = getUserId()

      sb.connect(userId, apiKey, (user, error) => {
        if (error) {
          return
        }
        commit(SET_SB_USER, user)
      })
    },
    sbDisconnect({ commit }) {
      let sb = SendBird.getInstance()
      sb.disconnect(() => {
        commit(RESET_SB_USER)
      })
    }
  }
}
