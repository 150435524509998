<template>
  <b-navbar toggleable="xl" type="light">
    <div v-if="isAdmin" class="is-admin">Admin Login</div>
    <b-container>
      <b-navbar-toggle target="nav-collapse" class="order-1 order-xl-1 mr-2" ></b-navbar-toggle>

      <b-navbar-brand
        v-if="$route.name === 'VerifyPhone'"
        class="order-2 order-xl-2 mr-0 ml-2"
      >
        <img src="../assets/logo.svg" alt="Bluuu" class="logo" />
      </b-navbar-brand>
      <b-navbar-brand :to="{ name: 'Home' }" v-else class="order-2 order-xl-2 mr-0 ml-2" >
        <img src="../assets/logo.svg" alt="Bluuu" class="logo" />
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav class="order-4 order-xl-3">
        <b-navbar-nav class="ml-auto">

          <b-nav-item-dropdown
            id="bookings-dropdown"
            :class="{
              active: $route.name === 'BookingOneTime' || $route.name === 'BookingMultiPackage',
            }"
            :text="$t('nav.bookings')"
            no-caret
          >
            <b-dropdown-item @click="onSelectBookingType('BookingOneTime')">{{
              $t('commons.book_one_time')
            }}</b-dropdown-item>
            <b-dropdown-item @click="onSelectBookingType('BookingMultiPackage')">
              {{ $t('pages.booking.purchase_multi_package') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="ml-10" :to="toRoute ? { name: 'Maids' } : ''">{{ $t('nav.maids') }}</b-nav-item>
          <b-nav-item class="ml-10" :to="toRoute ? { name: 'Pricing' } : ''">{{ $t('nav.pricing') }}</b-nav-item>
          <b-nav-item class="ml-10" :to="toRoute ? { name: 'Faq' } : ''">{{ $t('nav.faq') }}</b-nav-item>
          <!-- <b-nav-item v-if="$i18n.locale === 'en'" class="ml-10" @click="toArticlesPage('en')">{{ $t('nav.articles') }}</b-nav-item>
          <b-nav-item-dropdown v-else :text="$t('nav.articles')" right no-caret class="ml-10">
            <b-dropdown-item @click="toArticlesPage('th','customer')">{{
              $t('nav.for_customer')
            }}</b-dropdown-item>
            <b-dropdown-item @click="toArticlesPage('th','maid')">{{
              $t('nav.for_maid')
            }}</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <b-nav-item class="ml-10" :to="toRoute ? { name: 'AboutUs' } : ''">{{ $t('nav.about-us') }}</b-nav-item>
          <b-nav-item class="ml-10" :to="toRoute ? { name: 'ContactUs' } : ''">{{ $t('nav.contact-us') }}</b-nav-item>
          <b-nav-item-dropdown :text="currentLang" right no-caret class="dropdown-language ml-10 mr-4">
            <b-dropdown-item @click="setLng('th')">TH</b-dropdown-item>
            <b-dropdown-item @click="setLng('en')">EN</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
      

      <b-button
        class="order-3 order-xl-4"
        style="height: 40px ; margin-top: 4px"
        variant="primary"
        :class="{ 'bg-eee': !isUat }"
        @click="onSignIn"
        v-if="!isLoggedIn"
      >
        {{ $t('nav.sign_in') }}
    </b-button>
      <b-dropdown
        @show="onShowDropdown"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        right
        class="order-3 order-xl-4"
        v-if="isLoggedIn"
      >
        <template slot="button-content" style="position: relative">
          <b-img
            :src="profileURL"
            @error="(e) => (e.target.src = require('../assets/images/preview-upload_2.png'))"
            class="avatar"
            :class="{ 'bg-eee': !isUat }"
            alt="User"
            v-if="isLoggedIn"
          ></b-img>
          <span class="sr-only">User</span>
          <div
            v-if="notify && $route.name != 'MyBooking'"
            style="position: absolute; top: 8px; right: 8px"
            class="circle-badge"
          ></div>
        </template>
        <!-- <b-dropdown-item @click="onSignIn" v-if="!isLoggedIn">
          {{ $t('nav.sign_in') }}
        </b-dropdown-item> -->
        <b-dropdown-item :to="{ name: 'MyBooking' }" v-if="isLoggedIn">
          {{ $t('nav.my_booking') }}
          <div v-if="notify" class="circle-badge ml-2"></div>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'MyMultiPackage' }" v-if="isLoggedIn">
          {{ $t('nav.my_multi_package') }}
        </b-dropdown-item>
        <!-- <b-dropdown-item to="/chats" v-if="isLoggedIn"
          >{{ $t('nav.my_chat')}}
          <b-badge pill variant="danger" class="ml-2" v-if="totalUnreadMessageCount > 0">{{
            totalUnreadMessageCount
          }}</b-badge>
        </b-dropdown-item> -->
        <b-dropdown-item :to="{ name: 'History' }" v-if="isLoggedIn">
          {{ $t('nav.payment_history') }}
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'MyAccount' }" v-if="isLoggedIn">
          {{ $t('nav.my_account') }}
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'MyFavoriteMaid' }" v-if="isLoggedIn">
          {{ $t('nav.my_favorite_maid') }}
        </b-dropdown-item>

        <b-dropdown-item @click="logOut" v-if="isLoggedIn">
          {{ $t('nav.sign_out') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-container>
  </b-navbar>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { isProduction } from '../utils'
import { isUATEnv } from './../helpers/network'
import ApiClient from '../services'
const AuthStore = createNamespacedHelpers('auth')
const UserStore = createNamespacedHelpers('user')
// const sbMessage = createNamespacedHelpers('sbMessage')
@Component({
  computed: {
    ...AuthStore.mapState(['isLoggedIn', 'isNewUser']),
    ...UserStore.mapState(['image_url']),
    // ...sbMessage.mapState(['totalUnreadMessageCount']),
  },
  methods: {
    ...AuthStore.mapActions(['signOut', 'signIn']),
  },
})
export default class NavBar extends Vue {
  showAvatar = true
  upHere = false
  isClickProfile = true
  host = window.location.href
  notify = false
  alert_time_incorrect = false

  toRoute = false

  isUat = isUATEnv

  setLng(lng) {
    this.$i18n.locale = lng
    this.$router.replace({ params: { locale: lng } , query: this.$route.query})
  }
  onSelectBookingType(routeName) {
    this.$router.push({ name: routeName })
  }

  toArticlesPage(lang, page = '') {
    window.location = window.location.origin + '/articles/category/' + lang + '/' + page
  }

  get profileURL() {
    return this.image_url ? this.image_url : require('../assets/images/preview-upload_2.png')
  }

  async onSignIn() {
    // console.log("require-auth-modal", this.$root._isMounted);
    this.$root.$emit('bv::show::modal', 'require-auth-modal')
    let dropdown = document.getElementsByClassName('dropdown-menu dropdown-menu-right show')
    console.log(dropdown)
    //  console.log("yesss");
    // await this.signIn()
  }

  get currentLang() {
    return this.$i18n.locale === 'en' ? 'EN' : 'TH'
  }
  get isProduction() {
    return isProduction()
  }
  get isAdmin() {
    let adminToken = window.localStorage.getItem('admin_token')
    return this.isLoggedIn && adminToken && adminToken !== 'null'
  }

  @Watch('isNewUser')
  onIsNewUserChanged() {
    if (this.isNewUser) this.$router.push({ name: 'Home' })
  }

  async logOut() {
    await this.signOut()
    this.$router.push({ name: 'Home' })
  }

  debounceNotify = null
  async getReviewNotifyMenu() {
    try {
      clearTimeout(this.debounceNotify)
      this.debounceNotify = setTimeout(async () => {
      const response = await ApiClient.reviewNotifyMenu()
      const response2 = await ApiClient.reviewNotifyPayment()
      const current_date = response.data.current_date
      localStorage.setItem('currentDate', current_date || new Date().toISOString())
      this.notify = response.data.notify || response2.data.notify
      }, 1000)
      
      // const currentTime = new Date().getTime();
      // const dateThreshold = 1000 * 60 * 60; // 1 hour in milliseconds
      // const wrong_current_date = current_date && (Math.abs(new Date(current_date).getTime() - currentTime) > dateThreshold);
      // if (wrong_current_date && !this.isAdmin) {
      //   this.sendTimeIncorrectNotification()
      // }
    } catch (error) {
      console.log(error)
    }
  }

  sendTimeIncorrectNotification() {
    if (this.$route.name != 'Home') {
      this.alert_time_incorrect = false
      this.$router.push({ name: 'Home' })
    }else{
      if (!this.alert_time_incorrect) {
        this.alert_time_incorrect = true
        alert('เวลาของเครื่องคอมพิวเตอร์ของคุณไม่ถูกต้อง กรุณาตรวจสอบเวลาของเครื่องคอมพิวเตอร์ของคุณ')
        setTimeout(() => {
          this.alert_time_incorrect = false
        }, 1000*60*2);
      }
    }
  }

  async regetReviewNotifyMenu(){
    await this.getReviewNotifyMenu()
    setTimeout(() => {
     this.regetReviewNotifyMenu()
    }, 1000*60*10);
  }

  async regetCurentDate(){
    const response = await ApiClient.getCurrentTimeUTC()
    const current_date = response.data.current_date
    localStorage.setItem('currentDate', current_date || new Date().toISOString())
    setTimeout(() => {
     this.regetCurentDate()
    }, 1000*60*10);
  }

  async onShowDropdown() {
    // this.$store.dispatch('sbMessage/getTotalUnreadMessageCount')
    await this.getReviewNotifyMenu()
  }
  @Watch('$root._isMounted')
  isModalMounted(newVal) {
    console.log('ismounted', newVal)
  }
  updated() {}

  async mounted() {
    if (this.isLoggedIn) {
      await this.regetReviewNotifyMenu()
    } else {
      await this.regetCurentDate()
    }
    this.toRoute = true
  }
  get route() {
    return this.$route.name
  }
  @Watch('route')
  async reGetReviewNotifyMenu() {
    if (this.isLoggedIn) {
      await this.regetReviewNotifyMenu()
    } else {
      await this.regetCurentDate()
    }
  }

  @Watch('isLoggedIn')
  async onIsLoggedInChanged() {
    if (this.isLoggedIn) {
      await this.regetReviewNotifyMenu()
    } else {
      await this.regetCurentDate()
    }
  }
}
</script>
<style>
/* li#bookings-dropdown:hover a#bookings-dropdown__BV_button_ {
  color: rgba(0, 0, 0, 0.5) !important;
} */
.navbar-light .navbar-nav .active > .nav-link {
  color: #005dff !important;
}
.navbar-light .navbar-nav .active > .nav-link > span {
  border: 3px solid #005dff !important;
  color: #005dff;
}
/* #bookings-dropdown.show > #bookings-dropdown__BV_button_ > span{
  border: 3px solid #005dff !important;
} */
#bookings-dropdown.show > #bookings-dropdown__BV_toggle_ > span {
  border: 3px solid #005dff !important;
  color: #005dff;
}
li#bookings-dropdown:hover a#bookings-dropdown__BV_toggle_,
li#bookings-dropdown:hover a#bookings-dropdown__BV_button_ {
  color: rgba(0, 0, 0, 0.5) !important;
}
.btn:focus {
  outline: none;
  box-shadow: none !important;
}
</style>
<style scoped>
#bookings-dropdown >>> span {
  /* margin-left: -10px; */
  padding: 5px 10px;
  border: 3px solid #c4c4c4;
  border-radius: 5px;
}

#bookings-dropdown >>> span:hover {
  /* margin-left: -10px; */
  padding: 5px 10px;
  border-radius: 5px;
  border: 3px solid #005dff;
  color: #005dff;
}

.ml-10 {
  margin-left: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
}
.bg-eee {
  background-color: #eee;
}
.logo {
  width: 100px;
}

.beta-ribbon::before {
  line-height: 11px;
  content: 'BETA';
  position: absolute;
  top: -2px;
  left: 60px;
  font-size: 14px;
  background: red;
  color: white;
  padding: 2px 4px;
  box-sizing: border-box;
  border-radius: 4px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    height: auto !important;
  }
}
@media (min-width: 1200px) {
  header .navbar .nav-item:not(.dropdown-language)::before {
    content: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 1rem;
    background-color: #dddfe7;
  }
}
.circle-badge {
  display: inline-table;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  background-color: #ff695b;
  border-radius: 50%;
}

.is-admin {
  display: absolute;
  opacity: 0.3;
  width: 5px;
  height: 5px;
  font-size: 10px;
  z-index: 9999;
  color: red;
  white-space: nowrap;
}
</style>
