import get from 'lodash/get'

/**
 * Calculate totalPrice + Additional Price (equipment_price)
 * @param {Object} payment - payment object from payment history
 * @returns
 */
export const getTotalPrice = (payment = {}) => {

  const totalPrice = get(payment, 'total_price', 0)
  return totalPrice
}

export const getAllTotalPrice = (payment = {}) => {

  const totalPrice = get(payment, 'booking.total_price', 0)
  return totalPrice
}
