<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    if (!this.$route.path.startsWith('/en') && !this.$route.path.startsWith('/th')) {
      this.$router.push('/' + this.$i18n.locale + this.$route.fullPath)
    }
    window.localStorage.setItem("locale", this.$i18n.locale);
  },
  watch: {
    '$route' (to, from) {
      if (!to.path.startsWith('/en') && !to.path.startsWith('/th')) {
        this.$router.push('/' + this.$i18n.locale + to.fullPath)
      }
    }
  }
}
</script>

<style></style>
