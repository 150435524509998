import Vue from "vue";
import moment from "moment";
import momentTZ from "moment-timezone";
import { MERCHANT_ID, RELEASE_DATE_TIME } from "./config/constants";
import Constant from "./config/booking";
import jsPDF from "jspdf";
import ApiClient from "./services";
import { THSarabunNew } from "./helpers/receipt/font";
import { getTotalPrice } from "./helpers/receipt";

export const SIMULATE_PROD = false;
export const SIMULATE_UAT = false;

export const isProduction = () => {
  const host = window.location.host;
  return (
    host === "bluuu.co" ||
    host === "www.bluuu.co" ||
    host === "bluuu-prod.web.app" ||
    SIMULATE_PROD
  );
};

export const getMerchantId = () => MERCHANT_ID;

export const requestForGetLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(() => {});
  }
};

export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function post(path, params, method = "post") {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.acceptCharset = "UTF-8";
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
}

export const isToday = (dateTimeString) => {
  return (
    momentTZ(dateTimeString).isSame(momentTZ(), "day") &&
    momentTZ(dateTimeString).diff(momentTZ(), "hour") < 0
  );
};

export const minimumBookingMoment = () => momentTZ().add(1, "days");

export const deepCloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const createCalendarAttrDisabled = () => {
  const firstDateOfMonth = moment().date(1);
  const calendarAttributes = [];
  while (firstDateOfMonth.isBefore(moment())) {
    calendarAttributes.push({
      key: "busy",
      highlight: "gray",
      content: "gray",
      dates: new Date(firstDateOfMonth.toISOString()),
    });
    firstDateOfMonth.add(1, "day");
  }
  return calendarAttributes;
};

/**
 * คืนค่าอาร์เรย์ของวันเวลาที่เมดไม่สามารถรับงานได้ (ตารางเต็ม/วันนั้นไม่รับงาน)
 * @param {*} timeSlotList
 */
export const getDisabledDateFromTimeSlot = (timeSlotList) => {
  let upComingDays = {};
  timeSlotList
    .filter((d) => {
      const endHour = moment(d.end_datetime).hour(); // 0 = เที่ยงคืน
      const isBusy =
        (d.type === "BUSY" || d.type === "BOOKED") &&
        endHour <= 21 &&
        endHour !== 0;
      // isBusy && console.log(d, moment(d.end_datetime).hour());
      isBusy;
      return isBusy;
    })
    .forEach((d) => {
      const dates = moment(d.start_datetime).get("date");
      upComingDays[dates] = d;
    }); // เอาวันที่ไม่ว่างมาใส่ Object โดยที่ key = วันที่
  timeSlotList
    .filter((d) => {
      // เนื่องจากส่งเป็นช่วงเวลา หากเวลาสิ้นสุดอยู่ในช่วงทีจองได้ ต้องตัดเวลาเริ่มต้นบางส่วนออกก่อน
      if (
        d.type === "FREE" &&
        moment(d.end_datetime) > minimumBookingMoment()
      ) {
        // --------[--------ช่วงเวลาที่ส่งมา (ว่าง)---------]---
        //----------------[-----เวลาต่ำสุดที่จองได้------------]
        return moment(d.start_datetime) < minimumBookingMoment();
      }
      return true;
    }) // เอาเฉพาะวันที่มากกว่าวันที่สามารถบุคกิ้งได้
    .forEach((d) => {
      const dates = moment(d.start_datetime).get("date");
      upComingDays[dates] = null;
    }); // เอาวันที่ว่าง ไปลบออกจากวันที่ยุ่งอยู่

  const disabledDates = [];
  for (let key in upComingDays) {
    if (upComingDays[key]) {
      disabledDates.push(upComingDays[key]);
    }
  }
  return disabledDates;
};

/**
 * คืนค่าวันเวลาที่เมดว่าง (มีวันซ้ำ หากว่างมากกว่า 1 เวลาในวันเดียวกัน)
 * @param {*} timeSlotList
 */
export const getAvailableDateFromTimeSlot = (timeSlotList) => {
  return timeSlotList.filter((d) => {
    const startDate = moment(d.start_datetime);
    const endDate = moment(d.end_datetime);
    return (
      d.type === "FREE" &&
      startDate > minimumBookingMoment() &&
      !endDate.isSameOrBefore(startDate)
    );
  });
};

/**
 * คืนค่าอาเรย์ของ index เฉพาะวันที่ user ต้องการ recurrent
 * โดยตัดตัวซ้ำออก
 * @param {*} recurrentDate
 */
export const getRecurrentDateIndexes = (recurrentDate) => {
  const recurrentDateIndex = {};
  recurrentDate.forEach((d) => {
    recurrentDateIndex[d.date.value] = d.date.value;
  });
  return Object.values(recurrentDateIndex); // [0, 1, ..., 6]
};

/**
 *
 * @param {*} dateTimeObj
 */
export const createTimeSlotItem = ({
  number,
  start_datetime,
  end_datetime,
  status = null,
  maid_id = null,
  booking_id = null,
}) => {
  return {
    ...createEmptyTimeSlot(number),
    start_datetime,
    end_datetime,
    status: status,
    time: createHourOption(start_datetime, end_datetime),
    maid_id,
    booking_id,
  };
};

export const createHourOption = (start_datetime, end_datetime) => {
  if (!start_datetime || !end_datetime) return;
  const startDateTime = moment(start_datetime);
  const endDateTime = moment(end_datetime);
  return {
    startHour: startDateTime.hours(),
    startMinute: startDateTime.minutes(),
    endHour: endDateTime.hours(),
    endMinute: endDateTime.minutes(),
    text: `${startDateTime.format("H:mm")} - ${endDateTime.format("H:mm")}`,
  };
};

export const createEmptyHourOption = () => ({
  startHour: null,
  startMinute: null,
  endHour: null,
  endMinute: null,
  text: null,
});

export const createEmptyTimeSlot = (number) => ({
  start_datetime: null,
  end_datetime: null,
  number,
  time: createEmptyHourOption(),
  isAvailable: null,
  status: null,
  maid_id: null,
});

/**
 * Remove intersect data
 * ลบวันเวลาที่ซ้ำกันออก
 */
export const removeDuplicateTimeSlot = (targetTimeSlot, deductTimeSlot) => {
  return targetTimeSlot.filter((rc) => {
    return !deductTimeSlot.some(
      (slot) => slot.start_datetime === rc.start_datetime
    );
  });
};

export const splitArrayEveryNth = (arr, nth) => {
  const newArr = [];
  const totalRound = Math.floor(arr.length / nth);
  for (let i = 0; i < totalRound; i++) {
    const newSlice = arr.slice(i * nth, i * nth + nth);
    newArr.push([...newSlice]);
  }
  return newArr;
};

/**
 * Find latitude and longitude for place
 * @param {object} place - place from auto complete search OR Maps Picker
 * @returns {object} return place with latitude and longitude
 */
export const findLatLongIfNotExists = async (place) => {
  const {
    description = "",
    main_text = "",
    place_id = "",
    secondary_text = "",
    latitude = 0,
    longitude = 0,
    ...rest
  } = place;
  if (latitude === 0 && longitude === 0 && place_id) {
    try {
      const result = await ApiClient.mapsDetail(place_id, "en");
      const { location } = result.data.geometry;
      return {
        ...rest,
        description,
        main_text,
        place_id,
        secondary_text,
        latitude: location.lat,
        longitude: location.lng,
      };
    } catch (e) {
      console.log(e.response);
    }
  }

  return {
    ...rest,
    description,
    main_text,
    place_id,
    secondary_text,
    latitude,
    longitude,
  };
};

// Vue Filter
Vue.filter("formatDate", (dateTimeString, pattern = "") => {
  if (!dateTimeString) return ``;
  const locale = window.localStorage.getItem("locale") || "th";
  return momentTZ(dateTimeString).locale(locale).format(pattern);
});

Vue.filter("toCurrencyNumber", (value, withFragtion = false) => {
  return withFragtion
    ? parseFloat(value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

export const isAuthenticated = () => {
  const expiredAtStr = localStorage.getItem("expires_at");
  if (!expiredAtStr) return null;
  const expiresAt = JSON.parse(expiredAtStr);
  return new Date().getTime() < expiresAt;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const base64ToFile = (imgBase64, fileName) => {
  return new Promise((resolve, reject) => {
    fetch(imgBase64)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileName, { type: "image/png" });
        resolve(file);
      });
  });
};

export const exportPDFFromReceipt = async (paymentObj, label) => {
  const paperSize = {
    width: 210, // 21 cm
    height: 297, // 29.7 cm
    padding: 20,
  };
  const isCancelled = paymentObj.isCancelled;
  const booking_status = paymentObj.booking_status;
  if (booking_status === "Refunded 5%") {
    paymentObj.multiple_1 = 0.05;
    paymentObj.multiple_2 = 0.95;
  } else {
    paymentObj.multiple_1 = 0.5;
    paymentObj.multiple_2 = 0.5;
  }
  const bookingHistory = paymentObj.booking_update_history;
  const doc = new jsPDF();
  doc.addFileToVFS("THSarabunNew.ttf", THSarabunNew);

  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  doc.setFont("THSarabunNew");
  doc.setFontSize(14);
  const logo = new Image();
  logo.src = require("./assets/logo.png");
  doc.text(label.header, paperSize.width / 2, 24, {
    align: "center",
  });
  doc.addImage(logo, "png", paperSize.padding, 30, 210 * 0.18, 87 * 0.18); // (file, fileType, x, y, width, height)

  // Bluuu Address
  doc.text(label.company_name, paperSize.padding, 56);
  doc.text(label.primary_address, paperSize.padding, 62);
  doc.text(label.secondary_address, paperSize.padding, 68);
  doc.text(label.tax_id, paperSize.padding, 80);
  doc.text(label.tel_no, paperSize.padding, 86);
  doc.text(label.website, paperSize.padding, 92);

  // Booking date & receipt number
  if (isCancelled) {
    console.log("create_datetime", paymentObj.refund.create_datetime);
  } else {
    console.log(paymentObj.create_datetime);
  }
  const create_datetime = isCancelled
    ? moment(paymentObj.refund.create_datetime)
    : moment(paymentObj.create_datetime);

  // Convert to Buddist year (year + 543)
  label.lang === "th" && create_datetime.add(543, "year");

  const receiptID = isCancelled
    ? paymentObj.refund.receipt_id
    : paymentObj.receipt_id;

  doc.text(
    create_datetime.format(label.datetime_format),
    paperSize.width - paperSize.padding,
    56,
    {
      align: "right",
    }
  );
  doc.text(
    `${label.receipt_no} ${receiptID}`,
    paperSize.width - paperSize.padding,
    62,
    {
      align: "right",
    }
  );

  if (!isCancelled) {
    doc.text(
      `${label.customer_name}: ${paymentObj.first_name} ${
        paymentObj.last_name
      } (${formatPhoneNumber(paymentObj.phone)})`,
      paperSize.width - paperSize.padding,
      74,
      {
        align: "right",
      }
    );
  } else {
    doc.text(
      `${label.customer_name}: ${paymentObj.payment[0].first_name} ${
        paymentObj.payment[0].last_name
      } (${formatPhoneNumber(paymentObj.payment[0].phone)})`,
      paperSize.width - paperSize.padding,
      74,
      {
        align: "right",
      }
    );
  }

  let location = {};
  if (!isCancelled) {
    location.location_name = paymentObj.booking.location_name;
    location.location_secondary = paymentObj.booking.location_secondary;
  } else {
    location.location_name = paymentObj.location_name;
    location.location_secondary = paymentObj.location_secondary;
  }

  doc.text(
    `${location.location_name} ${location.location_secondary}`,
    paperSize.width - paperSize.padding,
    80,
    {
      align: "right",
      maxWidth: 80,
    }
  );
  // doc.text(`${formatPhoneNumber(paymentObj.phone)}`, paperSize.width - paperSize.padding, 92, {
  //   align: 'right'
  // })

  // Table header
  doc.text(label.item, paperSize.padding, 104);
  doc.text(label.description, paperSize.padding + 30, 104);
  doc.text(label.payment_method, paperSize.padding + 100, 104, {
    maxWidth: 20,
    align: "center",
  });

  if (isCancelled) {
    doc.text(
      label.date_refunded,
      paperSize.width - paperSize.padding - 32,
      104,
      {
        align: "right",
      }
    );
  } else {
    doc.text(label.date_paid, paperSize.width - paperSize.padding - 32, 104, {
      align: "right",
    });
  }
  doc.text(label.amount_paid, paperSize.width - paperSize.padding, 104, {
    align: "right",
  });
  doc.line(
    paperSize.padding,
    113,
    paperSize.width - paperSize.padding,
    113,
    "F"
  );
  // console.log(
  //   "paymentObj.refund.total_price",
  //   paymentObj.booking.payment_status
  // );
  // Table data
  const amountPaid = isCancelled
    ? `${parseFloat(paymentObj.refund.total_price)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
    : paymentObj.booking.type === "MULTI_PACKAGE"
    ? `${parseFloat(paymentObj.total_multi_price)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
    : `${parseFloat(
        (paymentObj.total_price * (100 / 107)) + paymentObj.discount
      )
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  doc.text("1", paperSize.padding + 7, 120);

  let newDuration = 0;
  if (!isCancelled) {
    newDuration = paymentObj.payment_booking_snapshot
      ? paymentObj.payment_booking_snapshot.duration
      : 0;
  } else {
    newDuration = paymentObj.duration;
  }

  let description = isCancelled ? `== ${label.refund} == ` : ``;

  const type = isCancelled ? paymentObj.type : paymentObj.booking.type;

  if (type === Constant.MULTI_PACKAGE) {
    const times = isCancelled
      ? paymentObj.multi_times
      : paymentObj.booking.multi_times;

    if (bookingHistory) {
      // Update booking
      // fix me - Add description when user can upgrade size of Multi-package
    } else {
      // New Booking
      description =
        label.lang === "en"
          ? `${description}Multi-Package Booking for ${newDuration} Hrs of Maid Service x ${times} credits`
          : `${description}จองแบบแพ็กเกจสำหรับบริการ${
              isCancelled ? `\n` : ``
            }แม่บ้าน ${newDuration} ชั่วโมง ${times} ครั้ง`;
    }
  } else {
    if (bookingHistory) {
      // Update Booking
      description =
        label.lang === "en"
          ? `${description}Additional fee for updating One-Time Booking from ${bookingHistory.duration} Hrs to ${newDuration} Hrs`
          : `${description}ค่าธรรมเนียมเพิ่มเติมสำหรับการเปลี่ยน การจองครั้งเดียวจาก ${bookingHistory.duration} ชั่วโมงเป็น ${newDuration} ชั่วโมง`;
    } else {
      // New Booking
      description =
        label.lang === "en"
          ? `${description}One-Time Booking for ${newDuration} Hrs of Maid Service`
          : `${description}จองครั้งเดียวสำหรับบริการแม่บ้าน ${newDuration} ชั่วโมง`;
    }
  }
  doc.text(description, paperSize.padding + 22, 120, {
    maxWidth: 65,
  });
  if (paymentObj.payment_method !== null) {
    if (paymentObj.payment_method === "paysolution") {
      doc.text(label.credit_card, paperSize.padding + 90, 120);
    } else {
      doc.text(label.bank_transfer, paperSize.padding + 90, 120);
    }
  }
  doc.text(
    create_datetime.format("DD/MM/YYYY"),
    paperSize.width - paperSize.padding - 32,
    120,
    {
      align: "right",
    }
  );
  doc.text(amountPaid, paperSize.width - paperSize.padding, 120, {
    align: "right",
  });
  doc.line(
    paperSize.padding,
    130,
    paperSize.width - paperSize.padding,
    130,
    "F"
  );

  // Summary
  doc.text(label.total, paperSize.width - paperSize.padding - 31, 136, {
    align: "right",
  });
  doc.text(amountPaid, paperSize.width - paperSize.padding, 136, {
    align: "right",
  });
  if (!isCancelled) {
    doc.text(label.discount, paperSize.width - paperSize.padding - 31, 142, {
      align: "right",
    });
    doc.text(
      `-${paymentObj.discount.toFixed(2)}`,
      paperSize.width - paperSize.padding,
      142,
      {
        align: "right",
      }
    );

    doc.text(
      `${label.vat} (7%)`,
      paperSize.width - paperSize.padding - 31,
      148,
      {
        align: "right",
      }
    );

    const vat_amount = isCancelled
      ? paymentObj.refund.total_price
      : parseFloat((paymentObj.total_price - paymentObj.total_price * (100 / 107))).toFixed(2);

    doc.text(`${vat_amount}`, paperSize.width - paperSize.padding, 148, {
      align: "right",
    });
  }

  // VAT ?

  if (isCancelled) {
    doc.text(label.refund, paperSize.width - paperSize.padding - 31, 154, {
      align: "right",
    });
    booking_status === "Refunded 5%"
      ? doc.text(
          `${label.cancellation_fee} (5%)`,
          paperSize.width - paperSize.padding - 31,
          160,
          {
            align: "right",
          }
        )
      : doc.text(
          `${label.cancellation_fee} (50%)`,
          paperSize.width - paperSize.padding - 31,
          160,
          {
            align: "right",
          }
        );

    // Refunt amount
    doc.text(
      `- ${parseFloat(paymentObj.refund.total_price)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
      paperSize.width - paperSize.padding,
      154,
      {
        align: "right",
      }
    );

    // Cancellation fee
    // console.log("status", booking_status, paymentObj);
    // console.log("status_2", booking_status, paymentObj);
    // console.log("status", paymentObj);
    doc.text(
      `${parseFloat(paymentObj.refund.total_price * paymentObj.multiple_1)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
      paperSize.width - paperSize.padding,
      160,
      {
        align: "right",
      }
    );
  }

  const additionalHeight = isCancelled ? 12 : 0;

  doc.line(
    paperSize.width - paperSize.padding - 60,
    152 + additionalHeight,
    paperSize.width - paperSize.padding,
    152 + additionalHeight,
    "F"
  );
  doc.text(
    label.grand_total,
    paperSize.width - paperSize.padding - 31,
    158 + additionalHeight,
    {
      align: "right",
    }
  );

  const grandTotal = isCancelled
    ? `- ${parseFloat(paymentObj.refund.total_price * paymentObj.multiple_2)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
    : `${parseFloat(getTotalPrice(paymentObj))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;

  doc.text(
    grandTotal,
    paperSize.width - paperSize.padding,
    158 + additionalHeight,
    {
      align: "right",
    }
  );

  const outputFileName = `bluuu_receipt_${receiptID}-${label.lang}.pdf`;
  doc.save(outputFileName);
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ``;
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6)}`;
};

export const clone = (from) => {
  return JSON.parse(JSON.stringify(from));
};
