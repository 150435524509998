import HttpRequest from './http_request'
import qs from 'qs'
import { BASIC_AUTH_CREDENTIALS } from '../config/constants'

/**
 * Configuration for header's request
 * @var configWithBasicAuth - Header object with Basic Authorization
 */
const configWithBasicAuth = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  auth: BASIC_AUTH_CREDENTIALS
}

/**
 * Configuration for header's request
 * @var config - Header object without Authorization
 */
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

/**
 * Configuration for header's request
 * @var configWithBearer - Header object with Authorization Bearer
 */
const configWithBearer = () => {
  let token = window.localStorage.getItem('access_token')
  let adminToken = window.localStorage.getItem('admin_token')
  let headers = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  if (adminToken && adminToken !== 'null') {
    headers.AdminToken = adminToken
  }
  headers['Current-Datetime'] = new Date().toISOString()
  return {
    headers
  }
}

/**
 * Class representing a ApiClient.
 * Used to connect with external API (Backend & Maps API
 * @extends HttpRequest - HttpRequest provide axios instance to connect with API
 */
class ApiClient extends HttpRequest {
  // API01 - User Register
  async userRegister(payload) {
    console.log('payload 01', payload)
    return await this.post('/v1.0/user/register', qs.stringify(payload), configWithBasicAuth)
  }

  // API02 - OAuth Token
  async oAuthToken(payload) {
    const reqBody = {
      username: payload.username,
      password: payload.password,
      grant_type: 'password',
      client_id: 'bluuu-client-id',
      client_secret: 'bluuu-client-secret'
    }
    return await this.post('/oauth/token', qs.stringify(reqBody), config)
  }
  //API02:Line
  async oAuthTokenLine(payload) {
    const reqBody = {
      username: payload.username,
      password: payload.password,
      grant_type: 'password',
      client_id: 'bluuu-client-id',
      client_secret: 'bluuu-client-secret'
    }

    let a = await this.post('/oauth/token', qs.stringify(reqBody), config)
    // console.log("a", a);
    return a
  }

  // API03 - New Token
  async newToken(payload) {
    const reqBody = {
      grant_type: 'refresh_token',
      client_id: 'bluuu-client-id',
      client_secret: 'bluuu-client-secret',
      refresh_token: payload.refresh_token
    }
    return await this.post('/oauth/token', qs.stringify(reqBody), config)
  }

  // API04 - Maid Detail
  async maidDetail(id) {
    let res = await this.get(`/v1.0/maid/id/${id}`, null, configWithBasicAuth)
    if (res.data && !res.data.service_types.includes('CLEANING')) {
      res.data.service_types.push('CLEANING')
    }
    return res
  }

  // API05 - Maid review list
  async maidReviewList(id, payload = null) {
    return await this.get(`/v1.0/maid/id/${id}/review/list`, payload, configWithBasicAuth)
  }
  async maidReviewListLastest(id, { page = 1, limit = 10 }) {
    return await this.get(
      `/v1.0/maid/id/${id}/review/list/latest?${qs.stringify({
        page,
        limit
      })}`,
      null,
      configWithBasicAuth
    )
  }

  // API06 - Maid List
  async maidList(payload) {
    return await this.get('/v1.0/maid/list', payload, configWithBasicAuth)
  }

  // API08 - Save to favorite maid
  async saveToFavMaid(maidID) {
    return await this.post(`/v1.0/maid/id/${maidID}/favorite/save`, null, configWithBearer())
  }

  // API09 - Save to favorite maid
  async unSaveToFavMaid(maidID) {
    return await this.post(`/v1.0/maid/id/${maidID}/favorite/unsave`, null, configWithBearer())
  }

  // API10 - My Favorite Maid
  async maidFavorite(maidID) {
    return await this.get(`/v1.0/maid/id/${maidID}/favorite`, null, configWithBearer())
  }

  // API11 - 12 - 13
  async booking(payload) {
    return await this.post('/v1.0/booking', payload, configWithBearer())
  }

  // API15 - Booking Detail
  async bookingDetail(bookingId) {
    return await this.get(`/v1.0/booking/id/${bookingId}`, null, configWithBearer())
  }

  // API16 - Myy Booking History
  async myBookingHistory(payload) {
    return await this.get('/v1.0/booking/calendar/history/list', payload, configWithBearer())
  }

  // API17 - My Booking Up Coming
  async myBookingUpComing(payload) {
    return await this.get('/v1.0/booking/calendar/upcoming/list', payload, configWithBearer())
  }

  // API18 - Get Friends
  async getFriends() {
    return await this.get(`/v1.0/user/friend/list`, null, configWithBearer())
  }

  // API19 - Maid Friend
  // เพื่อนบน Facebook ที่ใช้บริการเมดคนนี้
  async maidFriend(maidID) {
    return await this.get(`/v1.0/maid/id/${maidID}/friends/list`, null, configWithBearer())
  }

  // API20 - Maid list with friend
  // params order=rating&rating=3&no_of_reviews=2`
  async maidListWithFriend(params) {
    // return await this.get(`/v1.0/maid/list2`, params, configWithBearer())
    if (params.previosly_used || params.location_latitude || params.location_latitude)
      return await this.get(`/v1.0/maid/list2`, params, configWithBearer())
    return await this.get('/v1.0/maid/list', params, configWithBasicAuth)
  }

  // API - Maid List Admin Recommend

  async maidListAdminRecommend(params, isLoggedIn) {
    params.admin_recommend = true
    if(isLoggedIn && (params.previosly_used || params.location_latitude || params.location_latitude)){
      return await this.get(`/v1.0/maid/list2`, params, configWithBearer())
    }
    return await this.get('/v1.0/maid/list', params, configWithBasicAuth)
  }

  // API21 - Price One Timm
  async getPriceOneTime(hour) {
    return await this.get(`/v1.0/price/onetime?hours=${hour}`, null, configWithBasicAuth)
  }

  // API22 - Price Multi Package
  async getPriceMultiPackage(hour, packageId) {
    return await this.get(
      `/v1.0/price/multipackage?hours=${hour}&package=${packageId}`,
      null,
      configWithBasicAuth
    )
  }

  // API25 - Change Booking Calendar
  async changeBookingCalendar(payload) {
    const { bookingId, start_datetime, end_datetime } = payload
    return await this.post(
      `/v1.0/booking/calendar/id/${bookingId}/change`,
      { start_datetime, end_datetime },
      configWithBearer()
    )
  }

  // API31 - User Profile
  async userProfile() {
    return await this.get('/v1.0/user/profile', null, configWithBearer())
  }

  /**
   * @method GET - API32 - Maid Calendar All Month
   * @param {Object} payload - { maidId, month, year }
   * @param {Number} id - maid's id
   * @param {Number} month - i.e. 1, 2, 3, ..., 12
   * @param {Number} year - i.e. 2019
   */
  async maidCalendarAllMonth({ id, month, year }) {
    return await this.get(
      `/v1.0/maid/id/${id}/calender/month/${month}/year/${year}`,
      { me: true },
      configWithBearer(),
    )
  }

  // API35 - Update User Profile
  async updateUserProfile(payload) {
    return await this.post('/v1.0/user/profile', payload, configWithBearer())
  }

  // API38 - Maid Calendar Booking Total Hours
  async maidCalendarBookingTotalHours({ id, month, year }) {
    return await this.get(
      `/v1.0/maid/id/${id}/calender/month/${month}/year/${year}/totalhours`,
      null,
      configWithBasicAuth,
    )
  }

  // API39 - /v1.0/booking/location/history
  async bookingLocationHistory() {
    return await this.get('/v1.0/booking/location/history', null, configWithBearer())
  }

  // API43 - Payment History
  async paymentHistory(payload) {
    return await this.get('/v1.0/booking/payment/history', payload, configWithBearer())
  }

  // API44 - Contact us // fix me
  async contactUs(payload) {
    // return await this.post('/v1.0/contact/send', payload, configWithBasicAuth)
    var bodyFormData = new FormData()
    bodyFormData.append('user_name', payload.name)
    bodyFormData.append('user_email', payload.email)
    bodyFormData.append('user_expression_name', payload.help)
    bodyFormData.append('user_text', payload.message)
    bodyFormData.append('user_lang', payload.user_lang)
    return await this.request(
      'POST',
      'https://dev.houseofdev.tech/hod-mailer/bluuu_mailer.php',
      bodyFormData
    )
  }

  // API45 - Booking Cancel
  async cancelBooking(bookingId) {
    return await this.post(`/v1.0/booking/id/${bookingId}/cancel`, null, configWithBearer())
  }

  // API46 - Apply Coupon
  async applyCoupon(payload) {
    return await this.post('/v1.0/coupon/apply', payload, configWithBearer())
  }

  // API47 - Maid Recommended
  async maidRecommended() {
    return await this.get('/v1.0/maid/recommended/level', null, configWithBasicAuth)
  }

  // API48 - Maid Recommended Rating
  async maidRecommendedRating() {
    return await this.get('/v1.0/maid/recommended/rating', null, configWithBearer())
  }

  // API49 - Upload Profile Image
  async uploadProfileImage(formData) {
    return await this.post('/v1.0/user/profile/upload', formData, configWithBearer())
  }

  // API54 - My Booking Rating
  async myBookingRating() {
    return await this.get(`/v1.0/booking/calendar/rating/list`, null, configWithBearer())
  }

  // API55 - Review Maid
  async reviewMaid(payload, calendarId) {
    return await this.post(
      `/v1.0/booking/calendar/id/${calendarId}/review`,
      payload,
      configWithBearer()
    )
  }

  // API56 - My Favorite Maids
  async myFavoriteMaid() {
    return await this.get(`/v1.0/maid/favorite/list`, null, configWithBearer())
  }

  // API91 - My Multi Package List (Fix ME) -> limit
  async myMultiPackageList() {
    return await this.get(
      `/v1.0/booking/multipackage/list?page=1&limit=100`,
      null,
      configWithBearer()
    )
  }

  // API102 - Update Booking (Onetime & MultiPackage)
  async updateBooking(payload, id) {
    return await this.post(`/v1.0/booking/id/${id}/update`, payload, configWithBearer())
  }

  // API105 - Price One Time List
  async getPricingOneTimeList() {
    return await this.get(`/v1.0/price/onetime/list`, null, configWithBasicAuth)
  }

  // API106 - Price Multi Package List
  async getPricingMultiPackageList(payload) {
    return await this.get(`/v1.0/price/multipackage/list`, payload, configWithBasicAuth)
  }

  // API121 - Maid Calendar All Month Public
  async maidCalendarAllMonthPublic({ id, month, year }) {
    // console.log(
    //   `/v1.0/maid/id/${id}/calender/month/${month}/year/${year}/public`,
    //   configWithBasicAuth
    // )
    return await this.get(
      `/v1.0/maid/id/${id}/calender/month/${month}/year/${year}/public`,
      null,
      configWithBasicAuth
    )
  }

  // API122 Google Map Text Search
  async getPlaceByQuery(locationName, language) {
    return await this.get(
      `/v1.0/map/textsearch?query=${locationName}&language=${language}`,
      null,
      configWithBasicAuth
    )
  }

  // API124 - Update User FB Image
  async updateUserFBImage() {
    return await this.post(`/v1.0/user/profile/fbimage`, null, configWithBearer())
  }

  // API125 - User Logout
  async logout() {
    return this.post(`v1.0/user/logout`, null, configWithBearer())
  }

  // API127 - Profile maid
  async getProfileMaidByIdChat(userId) {
    return await this.get(`/v1.0/maid/user/id/${userId}`, null, configWithBearer())
  }

  // API129 - Booking Copy
  async bookingCopy(payload, id) {
    return await this.post(`/v1.0/booking/id/${id}/copy`, payload, configWithBearer())
  }

  // API130 - Check Booking Copy
  async checkBookingCopy(id) {
    return await this.get(`/v1.0/booking/id/${id}/copy`, null, configWithBearer())
  }

  //  API131 - Get Geocode
  async getPlaceByLatLng(latitude, longitude, language) {
    return await this.get(
      '/v1.0/map/geocode',
      { latitude, longitude, language },
      configWithBasicAuth
    )
  }

  // API132 - Check Phone Existing
  async checkPhoneExisting(phoneNumber) {
    return await this.get(`/v1.0/user/phone/${phoneNumber}/existing`, null, configWithBearer())
  }

  // API135 - Google Map Auto Complete
  async mapsAutoComplete(query, language) {
    return await this.get(
      `/v1.0/map/autocomplete`,
      {
        query,
        language
      },
      configWithBasicAuth
    )
  }

  // API136 - Google Map Details
  async mapsDetail(placeid, language) {
    return await this.get(`/v1.0/map/details`, { placeid, language }, configWithBasicAuth)
  }

  // API137 - Get Data Area
  async getDataArea() {
    return await this.get('/v1.0/data/area', null, configWithBasicAuth)
  }

  // API151 - Booking Refund History
  async refundHistory(payload) {
    return await this.get(`/v1.0/booking/refund/history`, payload, configWithBearer())
  }

  async maidAvailable(payload) {
    // let switched = configWithBearer();
    // if (switched.headers.Authorization.includes("null")) {
    //   switched = configWithBasicAuth;
    // }
    return await this.get(`/v1.0/maid/available`, payload, configWithBasicAuth)
  }
  //API161 - Email Login
  async logInEmail(payload) {
    const reqBody = {
      email: payload.email,
      password: payload.password
    }

    console.log(qs.stringify(reqBody))

    return await this.post(`/v1.0/user/email/login`, qs.stringify(reqBody), configWithBasicAuth)
  }
  //API163 - resetpassword
  async resetPasswordbyEmaill(payload) {
    const reqBody = {
      email: payload.email,
      lang: payload.lang
    }

    console.log(qs.stringify(reqBody))

    return await this.post(
      `/v1.0/user/email/resetpassword`,
      qs.stringify(reqBody),
      configWithBasicAuth
    )
  }

  async confirmPassword(payload) {
    const reqBody = {
      oobCode: payload.oobCode,
      newPassword: payload.newPassword
    }
    console.log('reqBody', reqBody)
    console.log(qs.stringify(reqBody))

    return await this.post(
      `/v1.0/user/email/confirmpassword`,
      qs.stringify(reqBody),
      configWithBasicAuth
    )
  }
  async changePassword(payload) {
    const reqBody = {
      password: payload
    }
    console.log('reqBody', reqBody)
    console.log(qs.stringify(reqBody))

    return await this.post(
      `/v1.0/user/email/changepassword`,
      qs.stringify(reqBody),
      configWithBearer()
    )
  }
  async changeEmail(payload) {
    const reqBody = payload
    console.log('reqBody', reqBody)
    console.log(qs.stringify(reqBody))

    return await this.post(
      `/v1.0/user/email/changeemail`,
      qs.stringify(reqBody),
      configWithBearer()
    )
  }

  async uploadSlip(payload) {
    var formdata = new FormData()
    formdata.append('image', payload.image, payload.image.name)

    return await this.post(
      `/v1.0/booking/id/${payload.booking_id}/payment/upload`,
      formdata,
      configWithBearer()
    )
  }
  async preBooking(payload) {
    // console.log("payload pre", JSON.stringify(payload));
    return await this.post('/v1.0/booking/pre', payload, configWithBearer())
  }
  async getMyBookingBankTransfer() {
    return await this.get('/v1.0/booking/calendar/banktransfer/list', null, configWithBearer())
  }
  async confirmBooking(payload) {
    const reqBody = payload
    console.log('reqBody', reqBody)
    console.log(qs.stringify(reqBody))
    return await this.post('/v1.0/booking/confirm', reqBody, configWithBearer())
  }

  // API - Booking Price
  async getBookingPrice({ duration, multi_times, type, bring_equipment }) {
    return await this.get(
      '/v1.0/price/booking',
      {
        duration,
        multi_times,
        type,
        bring_equipment
      },
      configWithBasicAuth
    )
  }

  // API181 - Option Price
  async getPriceOptions() {
    return await this.get('/v1.0/price/options', null, configWithBasicAuth)
  }

  //API - One Time Password
  async sentOTPToPhone(phoneNumber) {
    var bodyFormData = new FormData()
    bodyFormData.append('msisdn', phoneNumber)
    return await this.request(
      'POST',
      'https://dev.houseofdev.tech/hod-sms/bluuu/request_otp.php',
      bodyFormData
    )
  }

  async confirmOTP({ token, pin }) {
    var bodyFormData = new FormData()
    bodyFormData.append('token', token)
    bodyFormData.append('pin', pin)
    return await this.request(
      'POST',
      'https://dev.houseofdev.tech/hod-sms/bluuu/verify_otp.php',
      bodyFormData
    )
  }

  /**
   * API 185 - Maid Calendar All Month (Month-range)
   * @param {*} param0
   * @returns
   */
  async maidCalendarAllMonthByRange({ id, start_month, start_year, end_month, end_year }) {
    return await this.get(
      `/v1.0/maid/id/${id}/calender/start/month/${start_month}/year/${start_year}/end/month/${end_month}/year/${end_year}`,
      { me: true },
      configWithBearer(),
    )
  }

  /**
   * API 186 - Maid Calendar All Month PUBLIC(Month-range)
   * @param {*} param0
   * @returns
   */
  async maidCalendarAllMonthPublicByRange({ id, start_month, start_year, end_month, end_year }) {
    return await this.get(
      `/v1.0/maid/id/${id}/calender/start/month/${start_month}/year/${start_year}/end/month/${end_month}/year/${end_year}/public`,
      { me: true },
      configWithBasicAuth,
    )
  }

  // API - admin booking cache
  // async adminBookingsCache() {
  //   return await this.get(
  //     '/v1.0/admin/booking/list?search=&cache=false',
  //     null,
  //     {
  //       headers: {
  //         Authorization: `Basic Ymx1dXU6dXV1Ymw=`,
  //       }
  //     })
  // }  

  //API - Review Notify Menu
  async reviewNotifyMenu() {
    return await this.get('/v1.0/booking/calendar/notification/notify/type/menu', null, configWithBearer())
  }

  //API - Read Review Notify Menu
  async reviewReadMenu() {
    return await this.post('/v1.0/booking/calendar/notification/read/type/menu', null, configWithBearer())
  }

  //API - Review Notify History
  async reviewNotifyHistory() {
    return await this.get('/v1.0/booking/calendar/notification/notify/type/history', null, configWithBearer())
  }
  
  //API - Read Review Notify History
  async reviewReadHistory() {
    return await this.post('/v1.0/booking/calendar/notification/read/type/history', null, configWithBearer())
  }

    //API - Review Notify Payment
    async reviewNotifyPayment() {
      return await this.get('/v1.0/booking/calendar/notification/notify/type/payment', null, configWithBearer())
    }
    
    //API - Read Review Notify History
    async reviewReadPayment() {
      return await this.post('/v1.0/booking/calendar/notification/read/type/payment', null, configWithBearer())
    }

  //API - get current time utc
  async getCurrentTimeUTC() {
    return await this.get('/v1.0/data/dateTime', null, configWithBasicAuth)
  }

  //API - delete review
  async deleteReview(payload) {
    return await this.delete(`/v1.0/maid/id/${payload.id}/review/${payload.review_id}/cancel`, null, configWithBearer())
  }

  //API - get Articles from Wordpress
  async getArticles(payload) {
    return await this.get(`https://bluuu.co/articles/wp-json/custom/posts/category/${payload}`, null, configWithBasicAuth)
  }

  //API - get Article Image
  async getArticleMedia(id) {
    return await this.get(`https://bluuu.co/articles/wp-json/wp/v2/media/${id}`, null, configWithBasicAuth)
  }

  //API - Update User Accommodation Confirmation
  async updateUserAccommodationConfirmation() {
    return await this.post('/v1.0/user/profile/accommodation', null, configWithBearer())
  }

  //API - Get User Accommodation Confirmation
  async getUserAccommodationConfirmation() {
    return await this.get('/v1.0/user/profile/accommodation', null, configWithBearer())
  }
}

export default new ApiClient()
