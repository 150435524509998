import ApiClient from "../services";
import Constant, { STATUS } from "../config/booking";
import { isUATEnv } from "./../helpers/network";
import AuthStore from "./auth_module";
import {
  createTimeSlotItem,
  removeDuplicateTimeSlot,
  createEmptyTimeSlot,
  findLatLongIfNotExists,
  clone,
} from "../utils";
import moment from "moment";
import get from "lodash/get";

import router from "../router";

const log = (val) => {
  const prefix = "Booking Store: ";
  console.log(prefix, val);
};

const initForm = () => ({
  oneTime: {
    id: null,
    date: null,
    time: null,
    location: {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
      latitude: 0,
      longitude: 0,
    },
    accommodation: null,
    duration: null,
    services: null,
    additional: [],
    method: 0, // 0 = Auto, 1 = Manual
    maid: null,
    description: null,
    status: "",
    payment: null,
    bookingCalendars: [], // booking calendar from backend,
    isChangeMaid: false,
    isChangeMaidBefore: false,
    currentMaid: null, // this field will be set by booking detail
    deductPrice: 0,
  },
  multiPackage: {
    id: null,
    date: null,
    time: null,
    location: {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
      latitude: 0,
      longitude: 0,
    },
    accommodation: null,
    duration: null,
    services: null,
    additional: [],
    method: 0, // 0 = Auto, 1 = Manual
    maid: null,
    recurrentDates: [],
    recurrentList: [],
    recurrentItems: {},
    description: null,
    isChangeMaidBefore: false,
    isChangeMaid: false,
    currentMaid: null, // this field will be set by booking detail
    historyRecurrentItems: [], // this field will be set by booking detail
    bookingCalendars: [], // current booking calendar from backend
    bookingChangedMaid: [],
    notBookedTimes: 0,
    bookedTimes: 0,
    canChangeMaid: true, // เช็คจากโควต้า per user
    isAlreadyChangeMaid: false, // Booking นี้ถูกเปลี่ยนเมดไปแล้วหรือยัง ?
    preventFetchBooking: false,
    preventInitTimeSlot: false,
  },
  bookingReview: {
    id: null,
    date: null,
    time: null,
    location: null,
    accommodation: null,
    duration: null,
    services: null,
    additional: [],
    method: 0, // 0 = Auto, 1 = Manual
    maid: null,
    amount: 0,
    total: 0,
    type: "",
    isUpdateBooking: false, // for check `update booking` or `new booking`
    isChangeMaid: false,
    calendarPassed: [],
  },
  paymentBody: {
    merchantid: undefined,
    refno: undefined,
    customeremail: undefined,
    productdetail: undefined,
    total: undefined,
    coupon: undefined,
  },
  paymentResult: {
    errorMessage: undefined,
    status: undefined,
    type: undefined,
    isUpdateBooking: false,
  },
  myBookingOneTime: {
    end_datetime: null,
    id: 0,
    maid_review: null,
    no_of_reviews: 0,
    start_datetime: null,
    booking: {
      id: 0,
      type: "",
      maid: {
        id: 0,
        user: {
          first_name: "",
          id: 0,
          image_url: "",
          last_name: "",
        },
      },
    },
  },
  currentBookingOneTime: {
    description: "",
    additional: [],
    date: null,
    time: null,
    location: {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
      latitude: 0,
      longitude: 0,
    },
    accommodation: null,
    services: null,
    type: "",
    duration: null,
    method: 0,
    payment_total_price: 0,
    maid: null,
    equipment: null,
  },
  currentBookingMultiPackage: {
    location: {
      description: "",
      main_text: "",
      place_id: "",
      secondary_text: "",
      latitude: 0,
      longitude: 0,
    },
    additional: [],
    accommodation: null,
    services: null,
    description: "",
    calendarPassed: [],
    recurrentList: [],
    multi_times: 0,
    payment_total_price: 0,
  },
  isLog: false,
  slip_url: null,
  reduce: null,
  discount_price: 0,
});

function isAvailableTimeSlot(
   state,
  { start_datetime, end_datetime, booking_id, status }
) {
  if (!start_datetime) return null
  const startDate = moment(start_datetime)
  const endDate = moment(end_datetime)
  const month = startDate.get('month') + 1
  const year = startDate.get('year')
  let isCalendarExist = false
  if (!state.maidCalendar.hasOwnProperty(year)) {
    isCalendarExist = false
  } else {
    const yearCalendar = { ...state.maidCalendar[year] }
    isCalendarExist = yearCalendar.hasOwnProperty(month)
  }
  if (!isCalendarExist) return false
  const maidCalendar = state.maidCalendar[year][month]

  let matchedDate
  // console.log("status", status);
  // เช็คว่าแม่บ้านว่างไหม
  if (booking_id && status === 'APPROVED') {
    matchedDate = maidCalendar.find((d) => {
      return (
        d.start_datetime === start_datetime &&
        d.end_datetime === end_datetime &&
        ['BOOKED', 'RESERVED'].includes(d.type) &&
        d.booking.id == state.currentBookingId
      )
    }) // return object if maid is free, return undefined if maid is busy
  } else {
    matchedDate = maidCalendar.find((d) => {
      const maidStartDate = moment(d.start_datetime)
      const maidEndDate = moment(d.end_datetime)
      const isOwnCustomerBooking = d.booking
        ? ['BOOKED', 'RESERVED'].includes(d.type) && d.booking.id === state.currentBookingId
        : false
      return (
        startDate >= maidStartDate &&
        endDate <= maidEndDate &&
        (d.type === 'FREE' || isOwnCustomerBooking)
      )
    }) // return object if maid is free, return undefined if maid is busy
  }
  return Boolean(matchedDate)
}

function validateIsAvailableForTimeSlotsFunct({ state, dispatch, commit, rootState }) {
  console.log("validateIsAvailableForTimeSlots");
  const { recurrentList } = state.multiPackage;
  const totalRecurrentList = recurrentList.length;
  const newRecurrentList = [];
  for (let i = 0; i < totalRecurrentList; i++) {
    let isAvailable = isAvailableTimeSlot(rootState.calendar , recurrentList[i]);
    newRecurrentList.push({
      ...recurrentList[i],
      maid_id: recurrentList[i].maid_id ? recurrentList[i].maid_id : state.multiPackage.maid.id,
      isAvailable: isAvailable || recurrentList[i].status === STATUS.approved, // fix me - dirty hack
    });
  }
  commit("setTimeSlotsMultiPackage", newRecurrentList);
}

export default {
  namespaced: true,
  state: {
    ...initForm(),
    booking_raw: null,
  },
  mutations: {
    setReduce(state, payload) {
      state.reduce = payload;
    },
    setDiscountPrice(state, payload) {
      state.discount_price = payload;
    },
    setSlipURL(state, payload) {
      state.slip_url = payload;
    },
    bookingFormRaw(state, payload) {
      state.book_raw = payload;
    },
    setCurrentBookingOneTime(state, payload) {
      // console.log({ ...payload });
      state.currentBookingOneTime = { ...payload };
    },
    setCurrentBookingMultiPackage(state, payload) {
      state.currentBookingMultiPackage = payload;
    },
    setBookingOnetime(state, payload) {
      // Object.assign(state.oneTime, payload);
      state.oneTime = payload;
    },
    updateBookingOnetime(state, payload) {
      console.log("setBookingOnetime 2", payload);
      state.oneTime = { ...state.oneTime, ...payload };
    },
    updateBookingMultiPackage(state, payload) {
      state.multiPackage = { ...state.multiPackage, ...payload };
    },
    checkLog(state, payload) {
      console.log("payload", payload);
      state.isLog = payload ? payload.status === 400 : false;
    },
    setBookingReview(state, payload) {
      console.log("payload", payload);
      Object.assign(state.bookingReview, payload);

      localStorage.bookingState = JSON.stringify(state.bookingReview);
    },
    setPaymentBody(state, payload) {
      Object.assign(state.paymentBody, payload);
      localStorage.bodyPaySolution = JSON.stringify(state.paymentBody);
    },
    setPaymentResult(state, payload) {
      Object.assign(state.paymentResult, payload);
    },
    setMyBookingOneTime(state, payload) {
      Object.assign(state.myBookingOneTime, payload);
    },
    updateRecurrentItem(state, payload) {
      // console.log("sss",);
      // state.multiPackage.recurrentList=[]
      state.multiPackage.recurrentList = [
        ...state.multiPackage.recurrentList.filter(
          (timeSlot) => timeSlot.number !== payload.number
        ),
        payload,
      ].sort((a, b) => a.number - b.number);
    },
    resetState(state) {
      console.log("reset state");
      const s = initForm();
      console.log(s);
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    resetCurrentBookingOneTime(state) {
      state.currentBookingOneTime = {
        description: "",
        additional: [],
        date: null,
        time: null,
        location: {
          description: "",
          main_text: "",
          place_id: "",
          secondary_text: "",
          latitude: 0,
          longitude: 0,
        },
        accommodation: null,
        services: null,
        type: "",
        duration: null,
        method: 0,
        maid: null,
        equipment: null,
      };
    },
    resetCurrentBookingMultiPackage(state) {
      state.currentBookingMultiPackage = {
        location: {
          description: "",
          main_text: "",
          place_id: "",
          secondary_text: "",
          latitude: 0,
          longitude: 0,
        },
        additional: [],
        accommodation: null,
        services: null,
        description: "",
        calendarPassed: [],
        recurrentList: [],
        multi_times: 0,
        payment_total_price: 0,
      };
    },
    resetBookingReview(state) {
      state.bookingReview = {
        id: null,
        date: null,
        time: null,
        location: null,
        accommodation: null,
        duration: null,
        services: null,
        additional: [],
        method: 0, // 0 = Auto, 1 = Manual
        maid: null,
        amount: 0,
        total: 0,
        type: "",
        isUpdateBooking: false, // for check `update booking` or `new booking`
        isChangeMaid: false,
      };
    },
    resetBookingMultiPackage(state) {
      state.multiPackage = {
        id: null,
        date: null,
        time: null,
        location: {
          description: "",
          main_text: "",
          place_id: "",
          secondary_text: "",
          latitude: 0,
          longitude: 0,
        },
        accommodation: null,
        duration: null,
        services: null,
        additional: [],
        method: 0, // 0 = Auto, 1 = Manual
        maid: null,
        recurrentDates: [],
        recurrentList: [],
        recurrentItems: {},
        description: null,
        isChangeMaidBefore: false,
        isChangeMaid: false,
        currentMaid: null, // this field will be set by booking detail
        historyRecurrentItems: [], // this field will be set by booking detail
        bookingCalendars: [], // current booking calendar from backend
        bookingChangedMaid: [],
        notBookedTimes: 0,
        bookedTimes: 0,
        canChangeMaid: true, // เช็คจากโควต้า per user
        isAlreadyChangeMaid: false, // Booking นี้ถูกเปลี่ยนเมดไปแล้วหรือยัง ?
        preventFetchBooking: false,
      };
    },
    setMaidOneTime(state, payload) {
      state.oneTime.maid = {
        ...payload,
      };
      state.oneTime.method = 1;
    },
    setMaidMultiPackage(state, payload) {
      console.log("setMaidMultiPackage", payload);
      state.multiPackage.maid = {
        ...payload,
      };
      state.multiPackage.method = 1;
    },
    setCanChangeMaid(state, payload) {
      state.multiPackage.canChangeMaid = payload;
    },
    setTimeSlotsMultiPackage(state, payload) {
      state.multiPackage.recurrentList = [...payload];
    },
    setBookingCalendars(state, payload) {
      state.multiPackage.bookingCalendars = [...payload];
    },
    setBookingChangedMaid(state, payload) {
      state.multiPackage.bookingChangedMaid = [...payload];
    },
    setCalendarPassedMultiPackage(state, payload) {
      state.currentBookingMultiPackage.calendarPassed = [...payload];
    },
    setPreventFetchBooking(state, payload) {
      state.multiPackage.preventFetchBooking = payload;
    },
    setPreventInitTimeSlot(state, payload) {
      state.multiPackage.preventInitTimeSlot = payload;
    },
    setIsChangeMaidBefore(state, payload) {
      state.multiPackage.isChangeMaidBefore = payload;
    },
    setIsChangeMaid(state, payload) {
      state.multiPackage.isChangeMaid = payload;
    },
  },
  actions: {
    async preBooking({ commit, state }, autoApproved) {
      const bookingReview = JSON.parse(localStorage.bookingState);
      let { total, payment_status } = JSON.parse(localStorage.booking_detail);
      let type;
      if (bookingReview.type === "one-time") {
        if (bookingReview.method === 0) {
          type = Constant.ONE_TIME_AUTO_SELECT;
        } else {
          type = Constant.ONE_TIME_MANUAL_SELECT;
        }
      } else {
        type = Constant.MULTI_PACKAGE;
      }

      // Prepare location
      const location = await findLatLongIfNotExists({
        ...bookingReview.location,
      });

      const payload = {
        calendar: bookingReview.calendar,
        location_latitude: location.latitude || 0, //fix me (case select place from history)
        location_longitude: location.longitude || 0, //fix me
        location_place_id: location.place_id || "",
        location_name: location.main_text || "",
        location_secondary: location.secondary_text || "",
        location_additional_details: location.additional_details || "",
        accommodation: bookingReview.accommodation.value,
        duration: bookingReview.duration.value,
        service: bookingReview.services.value.split("_"),
        additional_info: [...bookingReview.additional],
        description: bookingReview.description || "",
        type,
        ref_no: state.paymentBody.refno,
        maid_id: bookingReview.maid ? bookingReview.maid.id : null,
        coupon: state.paymentBody.coupon,
        website_language: window.localStorage.getItem("locale")
          ? window.localStorage.getItem("locale").toUpperCase()
          : "TH",
        payment_status: payment_status,
        total_price: parseFloat(state.reduce ? state.reduce : (total * 1.07).toFixed(2)),
        bring_equipment: get(bookingReview, "equipment.bring_equipment", false),
      };

      if (type === Constant.MULTI_PACKAGE) {
        payload.multi_times = bookingReview.multi_times;
      }

      // fix me - this condition maybe not used
      if (bookingReview.isUpdateBooking) {
        payload.id = bookingReview.id;
      }

      if (bookingReview.isUpdateBooking && (payment_status == "ONLINE_BANKING_WAITING" || payment_status == "BANK_TRANSFER_WAITING")) {
        payload.updated_booking_id = bookingReview.id;
      }

      if (autoApproved) {
        payload.status = "APPROVED";
      }
      console.log("PAYLOAD for Bank", state.reduce);

      delete payload.ref_no;
      // payload.payment_status = "BANK_TRANSFER_WAITING";
      console.table(payload);
      console.log(JSON.stringify(payload));
      let result_3 = null;
      try {
        console.log("payload preBooking", payload);
        result_3 = await ApiClient.preBooking(payload);
        // console.log("result payment", result_3);
        // commit("setCanChangeMaid", result.data);
      } catch (e) {
        commit("checkLog", e.response);
        console.log("error preBooking", e.response);
        let result = e.response ? e.response : e
        if (typeof result === "object") {
          result = JSON.stringify(result);
        }
        result = "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ \nError: Please contact admin \n \n" + result;
        alert(result)
        // throw e;
      }

      return result_3;
    },
    async uploadSlip({ commit, state }, payload) {
      console.log("paylaod upload", payload);
      try {
        let result = await ApiClient.uploadSlip(payload);
        console.log("result", result);
        return result.data;
        // commit("setCanChangeMaid", result.data);
      } catch (e) {
        // commit("checkLog", e.response);

        console.log("error", e.response);
        return e;
      }
    },
    async processBooking({ commit, state }, autoApproved) {
      const bookingReview = state.bookingReview;
      let type;
      if (bookingReview.type === "one-time") {
        if (bookingReview.method === 0) {
          type = Constant.ONE_TIME_AUTO_SELECT;
        } else {
          type = Constant.ONE_TIME_MANUAL_SELECT;
        }
      } else {
        type = Constant.MULTI_PACKAGE;
      }

      // Prepare location
      const location = await findLatLongIfNotExists({
        ...bookingReview.location,
      });

      const payload = {
        calendar: bookingReview.calendar,
        location_latitude: location.latitude || 0, //fix me (case select place from history)
        location_longitude: location.longitude || 0, //fix me
        location_place_id: location.place_id || "",
        location_name: location.main_text || "",
        location_secondary: location.secondary_text || "",
        location_additional_details: location.additional_details || "",
        accommodation: bookingReview.accommodation.value,
        duration: bookingReview.duration.value,
        service: bookingReview.services.value.split("_"),
        additional_info: [...bookingReview.additional],
        description: bookingReview.description || "",
        type,
        ref_no: state.paymentBody.refno,
        maid_id: bookingReview.maid ? bookingReview.maid.id : null,
        coupon: state.paymentBody.coupon,
        website_language: window.localStorage.getItem("locale")
          ? window.localStorage.getItem("locale").toUpperCase()
          : "TH",
      };
      if (type === Constant.MULTI_PACKAGE) {
        payload.multi_times = bookingReview.multi_times;
      }

      // fix me - this condition maybe not used
      if (bookingReview.isUpdateBooking) {
        payload.id = bookingReview.id;
      }

      if (autoApproved) {
        payload.status = "APPROVED";
      }
      console.log("PAYLOAD for Booking");
      console.table(payload);

      console.log(JSON.stringify(payload));
      let result_3 = null;
      try {
        result_3 = await ApiClient.booking(payload);
        // commit("setCanChangeMaid", result.data);
      } catch (e) {
        commit("checkLog", e.response);
        console.log(e.response);
      }

      return result_3;
    },
    async processBooking_log({ commit, state }, autoApproved) {
      const bookingReview = JSON.parse(localStorage.bookingState);
      let type;
      if (bookingReview.type === "one-time") {
        if (bookingReview.method === 0) {
          type = Constant.ONE_TIME_AUTO_SELECT;
        } else {
          type = Constant.ONE_TIME_MANUAL_SELECT;
        }
      } else {
        type = Constant.MULTI_PACKAGE;
      }

      // Prepare location
      const location = await findLatLongIfNotExists({
        ...bookingReview.location,
      });

      const payload = {
        calendar: bookingReview.calendar,
        location_latitude: location.latitude || 0, //fix me (case select place from history)
        location_longitude: location.longitude || 0, //fix me
        location_place_id: location.place_id || "",
        location_name: location.main_text || "",
        location_secondary: location.secondary_text || "",
        location_additional_details: location.additional_details || "",
        accommodation: bookingReview.accommodation.value,
        duration: bookingReview.duration.value,
        service: bookingReview.services.value.split("_"),
        additional_info: [...bookingReview.additional],
        description: bookingReview.description || "",
        type,
        ref_no: state.paymentBody.refno,
        maid_id: bookingReview.maid ? bookingReview.maid.id : null,
        coupon: state.paymentBody.coupon,
        website_language: window.localStorage.getItem("locale")
          ? window.localStorage.getItem("locale").toUpperCase()
          : "TH",
      };
      if (type === Constant.MULTI_PACKAGE) {
        payload.multi_times = bookingReview.multi_times;
      }

      // fix me - this condition maybe not used
      if (bookingReview.isUpdateBooking) {
        payload.id = bookingReview.id;
      }

      if (autoApproved) {
        payload.status = "APPROVED";
      }
      console.log("PAYLOAD for Booking");
      console.table(payload);

      console.log(payload);
      let result_3 = null;
      try {
        // await ApiClient.booking(payload);
        // commit("setCanChangeMaid", result.data);
      } catch (e) {
        commit("checkLog", e.response);
        console.log(e.response);
      }

      return;
    },

    async updateBooking({ state }) {
      const bookingReview = state.bookingReview;
      console.log("bookingReview",bookingReview);
      let type;
      if (bookingReview.type === "one-time") {
        if (bookingReview.method === 0) {
          type = Constant.ONE_TIME_AUTO_SELECT;
        } else {
          type = Constant.ONE_TIME_MANUAL_SELECT;
        }
      } else {
        type = Constant.MULTI_PACKAGE;
      }

      const location = await findLatLongIfNotExists({
        ...bookingReview.location,
      });

      const payload = {
        calendar: bookingReview.calendar,
        location_latitude: location.latitude || 0, //fix me (case select place from history)
        location_longitude: location.longitude || 0, //fix me
        location_place_id: location.place_id || "",
        location_name: bookingReview.location.main_text || "",
        location_secondary: bookingReview.location.secondary_text || "",
        location_additional_details: location.additional_details || "",
        accommodation: bookingReview.accommodation.value,
        duration: bookingReview.duration.value,
        service: bookingReview.services ? bookingReview.services.value.split("_") : [],
        additional_info: [...bookingReview.additional],
        description: bookingReview.description ? bookingReview.description : "",
        maid_id: bookingReview.maid ? bookingReview.maid.id : null,
        type,
        multi_times: bookingReview.multi_times,
        website_language: window.localStorage.getItem("locale")
          ? window.localStorage.getItem("locale").toUpperCase()
          : "TH",
        total_price : parseFloat((bookingReview.total * 1.07).toFixed(2))
      };
      state.paymentBody.refno && (payload.ref_no = state.paymentBody.refno);
      if (bookingReview.isChangeMaid) {
        payload.maid_id = type === Constant.ONE_TIME_AUTO_SELECT ? null : bookingReview.maid.id;
        payload.calendar_passed = bookingReview.calendarPassed || [];
        payload.bring_equipment = get(bookingReview, "equipment.bring_equipment", false);
        payload.total_price = bookingReview.total_price + parseFloat((bookingReview.total * 1.07).toFixed(2));
        payload.payment_status = bookingReview.payment_status;
      }
      if (
        payload.maid_id &&
        bookingReview.currentType == 'ONE_TIME_AUTO_SELECT' &&
        bookingReview.status !== 'AWAITING' &&
        bookingReview.currentMaid &&
        bookingReview.currentMaid.id == payload.maid_id
      ) {
        payload.type = Constant.ONE_TIME_AUTO_SELECT
      }
      console.table(payload);
      console.log(payload);
      console.log(bookingReview.id);
      if (bookingReview.isChangeMaid) {
        console.log("CHANGEMAID & Call API 129");
        console.log("Booking Copy", payload, bookingReview);
        payload.calendar = payload.calendar.map((calendar) => {
          return { ...calendar, block_start: true, block_end: true };
        });
        payload.payment_status = bookingReview.payment_status
        return await ApiClient.bookingCopy(payload, bookingReview.id);
      }
      console.log("update Booking");
      return await ApiClient.updateBooking(payload, bookingReview.id);
    },
    setMaidForBooking({ state, commit }, { maid, type }) {
      commit("calendar/setCurrentBookingId", null, { root: true });
      if (type === "one-time") {
        if (state.oneTime.currentMaid) {
          // Case maid reject booking OT
          const isChangeMaidBefore = state.oneTime.isChangeMaidBefore;
          const isChangeMaid = state.oneTime.currentMaid.id !== maid.id;
          commit("updateBookingOnetime", {
            ...state.oneTime,
            isChangeMaid,
            isChangeMaidBefore,
          });
        } else if (
          !state.oneTime.currentMaid &&
          state.currentBookingOneTime.type === "ONE_TIME_AUTO_SELECT"
        ) {
          // Case: no maid accept after payment completed (24hr)
          const isChangeMaidBefore = state.oneTime.isChangeMaidBefore;
          commit("updateBookingOnetime", {
            ...state.oneTime,
            isChangeMaid: true,
            isChangeMaidBefore,
          });
        }
        commit("setMaidOneTime", maid);
      } else {
        if (state.multiPackage.currentMaid) {
          const { id } = state.multiPackage.currentMaid;
          const isChangeMaidBefore = false;
          const isChangeMaid = id !== maid.id;
          commit("updateBookingMultiPackage", {
            ...state.multiPackage,
            isChangeMaid,
            isChangeMaidBefore,
          });
        }
        commit("setMaidMultiPackage", maid);
      }
    },
    /**
     * สร้าง TimeSlot ทั้งหมดสำหรับ Booking Multi-Package
     * โดยสร้าง Timeslot จาก Recurrent list เดิมที่มีอยู่
     * แล้วเพิ่ม Empty timeslot เพิ่มเข้าไปให้ครบตามจำนวนครั้งของแพ็กเกจ
     */
    createTimeSlotsFromBookingCalendar({ state, commit, getters }) {
      const { multiPackage } = state;
      const bookingCalendarFromPreviousMaid = getters.calendarChangedMaid;
      let timeSlots = [
        ...bookingCalendarFromPreviousMaid,
        ...multiPackage.bookingCalendars.map((timeSlot) => createTimeSlotItem(timeSlot)),
      ].sort((a, b) => a.number - b.number);

      // กำหนด number (make sure)
      timeSlots = timeSlots.map((booking, index) => ({
        ...booking,
        number: index + 1,
      }));

      const remainingCredits = multiPackage.package.value - timeSlots.length;
      console.log(remainingCredits);

      if (remainingCredits > 0) {
        const multi_times = multiPackage.package.value;
        const bookedCredits = timeSlots.length;
        for (let i = bookedCredits + 1; i <= multi_times; i++) {
          timeSlots.push(createEmptyTimeSlot(i));
        }
      }
      commit("updateBookingMultiPackage", {
        ...state.multiPackage,
        recurrentList: timeSlots,
      });
    },
    /**
     * สร้าง Timeslot สำหรับ Multi-Package
     */
    createTimeSlotsMultiPackage({ state, commit, getters }) {
      const { multiPackage } = state;

      // Package is requried
      if (!multiPackage.package) {
        console.error("No Package");
        return;
      }

      let current_date = localStorage.getItem('currentDate') || new Date()
      let moment_date = moment(current_date)
      let adminToken = window.localStorage.getItem('admin_token')
      if (adminToken && adminToken !== 'null') {
        moment_date = moment()
      }

      // TimeSlot จากแม่บ้านใน Package เก่า
      const bookingCalendarFromPreviousMaid = getters.calendarChangedMaid;

      // TimeSlot ที่ผ่านมาแล้วของแม่บ้านคนปัจจุบัน (Passed and Approved)
      // จะเป็น List ว่างในกรณีที่ไม่มี slot ถูกใช้งาน
      console.log("historyTimeSlots -",bookingCalendarFromPreviousMaid);
      console.log("historyTimeSlots -",multiPackage.historyRecurrentItems);
      let historyTimeSlots = clone([
        ...bookingCalendarFromPreviousMaid,
        ...multiPackage.historyRecurrentItems,
      ]); // Avoid mutate state
      console.log("historyTimeSlots", historyTimeSlots);
      historyTimeSlots = historyTimeSlots.filter((timeSlots) => {
        console.log("historyTimeSlots .", moment_date.add(3, "hours").isBefore(moment(timeSlots.start_datetime)))
        return !moment_date.add(3, "hours").isBefore(moment(timeSlots.start_datetime));
      });
      console.log("historyTimeSlots", historyTimeSlots);
      // กำหนด number (make sure)
      historyTimeSlots = historyTimeSlots.map((booking, index) => ({
        ...booking,
        isAvailable: true,
        number: index + 1,
      }));

      // จำนวนของ History time slots
      const totalHistoryTimeSlots = historyTimeSlots.length;

      // จำนวนของ empty time slot ที่ต้องสร้างเพิ่ม
      const numberOfEmptyTimeSlots = multiPackage.package.value - totalHistoryTimeSlots;

      // ประกาศ default time slots
      const recurrentList = [...historyTimeSlots];
      for (let i = 1; i <= numberOfEmptyTimeSlots; i++) {
        const number = i + totalHistoryTimeSlots;
        const timeSlot = createEmptyTimeSlot(number);
        recurrentList.push(timeSlot);
      }
      console.log("setTimeSlotsMultiPackage 2", recurrentList);
      commit("setTimeSlotsMultiPackage", recurrentList);
      commit("updateBookingMultiPackage", { ...multiPackage, recurrentList });
      return recurrentList;
    },
    async checkBookingCopy({ state, commit }) {
      const { id } = state.multiPackage;
      if (id) {
        try {
          const result = await ApiClient.checkBookingCopy(id);
          // console.log("can changeMaid", result.data);
          commit("setCanChangeMaid", result.data);
        } catch (e) {
          console.log(e.response);
        }
      }
    },
    async fetchCalendarForTimeSlots({ state, commit, dispatch, rootState }, isLogin) {
      const monthYearObject = {};
      let { recurrentList, maid } = state.multiPackage;
      if (!recurrentList || recurrentList.length === 0) {
        commit("calendar/setIsInitializeTimeSlot", false, { root: true });
        return;
      }
      recurrentList.forEach((timeSlot) => {
        if (timeSlot.start_datetime) {
          const month = moment(timeSlot.start_datetime).get("month") + 1;
          const year = moment(timeSlot.start_datetime).get("year");
          monthYearObject[month] = {
            month,
            year,
            id: maid.id,
          };
        }
      });

      commit("calendar/setIsInitializeTimeSlot", true, { root: true });
      // find month range
      recurrentList = recurrentList.filter((timeSlot) => {
        return timeSlot.start_datetime;
      });
      let maidCalendarByRange = [];

      if (recurrentList.length !== 0) {

      const firstDate = moment(recurrentList[0].start_datetime);
      const startMonth = firstDate.get("month") + 1;
      const startYear = firstDate.get("year");
      const lastDate = moment(recurrentList[recurrentList.length - 1].start_datetime);
      const endMonth = lastDate.get("month") + 1;
      const endYear = lastDate.get("year");

      // find maid's calendar using month range
      const response = isLogin
        ? await ApiClient.maidCalendarAllMonthByRange({
            id: maid.id,
            start_month: startMonth,
            start_year: startYear,
            end_month: endMonth,
            end_year: endYear,
          })
        : await ApiClient.maidCalendarAllMonthPublicByRange({
            id: maid.id,
            start_month: startMonth,
            start_year: startYear,
            end_month: endMonth,
            end_year: endYear,
          });
        
        maidCalendarByRange = response.data;
      }
      // group each date by month and year
      const maidCalendarByMonthYear = maidCalendarByRange.reduce((acc, d) => {
        const month = moment(d.start_datetime).get("month") + 1;
        const year = moment(d.start_datetime).get("year");
        const key = `${month}-${year}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(d);
        return acc;
      }, {});

      // Update each maid's calendar (grouped by month) into state
      const monthYearKeys = Object.keys(maidCalendarByMonthYear);
      for (const k of monthYearKeys) {
        const [month, year] = k.split("-");
        const maidCalendar = maidCalendarByMonthYear[k];
        dispatch(
          "calendar/updateMaidCalendarByMonth",
          {
            year: year,
            month: month,
            calendar: maidCalendar,
            id: maid.id,
          },
          {
            root: true,
          }
        );
        dispatch("calendar/calculateTotalHoursFromTimeSlots", maidCalendar, {
          root: true,
        });
      }
      commit("calendar/setIsInitializeTimeSlot", false, {
        root: true,
      });
      // await dispatch("validateIsAvailableForTimeSlots");
      validateIsAvailableForTimeSlotsFunct({ state, dispatch, commit, rootState });
    },
    async validateIsAvailableForTimeSlots({ state, dispatch, commit }) {
      console.log("validateIsAvailableForTimeSlots");
      const startTimer = new Date();
      const { recurrentList } = state.multiPackage;
      const totalRecurrentList = recurrentList.length;
      const newRecurrentList = [];
      for (let i = 0; i < totalRecurrentList; i++) {
        let isAvailable = await dispatch("calendar/isAvailableTimeSlot", recurrentList[i], {
          root: true,
        });
        newRecurrentList.push({
          ...recurrentList[i],
          maid_id: recurrentList[i].maid_id ? recurrentList[i].maid_id : state.multiPackage.maid.id,
          isAvailable: isAvailable || recurrentList[i].status === STATUS.approved, // fix me - dirty hack
        });
      }
      console.log("Timer is ", new Date() - startTimer);
      commit("setTimeSlotsMultiPackage", newRecurrentList);
    },
    async fetchMaidDetailForBookingChangedMaid({ state, dispatch }) {
      const { bookingChangedMaid } = state.multiPackage;
      const maidIdList = bookingChangedMaid
        ? bookingChangedMaid.map((booking) => booking.maid_id)
        : [];
      const totalElements = maidIdList.length;
      for (let i = 0; i < totalElements; i++) {
        await dispatch("maid/getMaidByMaidId", maidIdList[i], { root: true });
      }
    },
    async checkPendingBookingIsExist() {
      const isExists = window.localStorage.getItem("booking_detail");
      if (isExists) {
        router.push({ name: "PaymentResult" });
      }
    },
    async initBookingOneTime({ state, commit }) {
      // หลังจากเปลีี่ยนแม่บ้าน
      if (state.oneTime.isChangeMaid) {
        commit("updateBookingOnetime", {
          ...state.oneTime,
          date: null,
          time: null,
          duration: null,
        });
      }
    },
    calculateCalendarPassed({ state, commit }) {
      let current_date = localStorage.getItem('currentDate') || new Date()
      let moment_date = moment(current_date)
      let adminToken = window.localStorage.getItem('admin_token')
      if (adminToken && adminToken !== 'null') {
        moment_date = moment()
      }
      const calendar_passed = state.multiPackage.recurrentList
        .filter((booking) => {
          if (booking.status !== STATUS.approved) return false;
          const { start_datetime, end_datetime } = booking;
          return (
            moment_date > moment(end_datetime) || // Passed
            moment(start_datetime).diff(moment_date, "seconds") <= 60 * 60 * 3 // Less than 3 hrs of appointment
          );
        })
        .map((booking) => booking.number);
      commit("setCalendarPassedMultiPackage", calendar_passed);
      console.log("Calculate calendar passed and commit to store state", calendar_passed);
    },
    fillTimeSlot({ state, commit }, recurrentList = null) {
      const { multiPackage } = state;
      let timeslot = recurrentList ? recurrentList : multiPackage.recurrentList;

      // re-indexed
      timeslot = timeslot.map((booking, index) => ({
        ...booking,
        number: index + 1,
      }));

      const remainingCredits = multiPackage.package.value - timeslot.length;
      console.log(remainingCredits);

      if (remainingCredits > 0) {
        const multi_times = multiPackage.package.value;
        const bookedCredits = timeslot.length;
        for (let i = bookedCredits + 1; i <= multi_times; i++) {
          timeslot.push(createEmptyTimeSlot(i));
        }
      }
      commit("updateBookingMultiPackage", {
        ...state.multiPackage,
        recurrentList: timeslot,
      });
    },
  },
  getters: {
    calendarForNewBookingPayload(state) {
      const calendar = state.multiPackage.recurrentList
        .filter((rc) => !!rc.start_datetime && rc.isAvailable) // ตัด slot ที่มี start_datetime เป็น null ทิ้ง
        .sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime)) // เรียงวันจากน้อยไปมาก
        .map((rc, index) => {
          const { start_datetime, end_datetime } = rc;
          return {
            number: index + 1,
            start_datetime,
            end_datetime,
          };
        });
      return calendar;
    },
    calendarForUpdateBookingPayload(state, { calendarChangedMaid }) {
      const { isChangeMaid, recurrentList, historyRecurrentItems } = state.multiPackage;
      const defaultCalendar = recurrentList
        .filter((rc) => !!rc.start_datetime && rc.isAvailable) // ตัด slot ที่มี start_datetime เป็น null ทิ้ง
        .sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime)); // เรียงจากน้อยไปมาก

      const totalCalendarChangedMaid = calendarChangedMaid.length;

      if (isChangeMaid) {
        let calendar = removeDuplicateTimeSlot(defaultCalendar, [
          ...calendarChangedMaid,
          ...historyRecurrentItems,
        ]); // เอา timeslot ที่ผ่านไปแล้วออก
        // คำนวณ number ใหม่
        const totalPrevTimeSlots = historyRecurrentItems.length + totalCalendarChangedMaid;
        calendar = calendar.map((rc, index) => {
          const { start_datetime, end_datetime } = rc;
          return {
            number: index + totalPrevTimeSlots + 1,
            start_datetime,
            end_datetime,
          };
        });
        return calendar;
      } else {
        // Update Booking
        let calendar = removeDuplicateTimeSlot(defaultCalendar, calendarChangedMaid);
        calendar = calendar.map((rc, index) => {
          const { start_datetime, end_datetime, status } = rc;
          const timeSlot = {
            number: index + totalCalendarChangedMaid + 1,
            start_datetime,
            end_datetime,
          };
          if (
            status === STATUS.updateAwaiting ||
            status === STATUS.updateApproved ||
            status === STATUS.approved
          ) {
            timeSlot.status = status;
          }
          if (status === STATUS.awaiting) {
            timeSlot.status = STATUS.updateAwaiting;
          }
          return timeSlot;
        }); // สร้าง index ใหม่
        return calendar;
      }
    },
    calendarChangedMaid(state) {
      const bookingChangedMaid = state.multiPackage.bookingChangedMaid;
      const bookingCalendarFromPreviousMaid = [];
      if (bookingChangedMaid && bookingChangedMaid.length > 0) {
        bookingChangedMaid.forEach(({ booking_calendars, maid_id }) => {
          booking_calendars.forEach((timeSlot) =>
            bookingCalendarFromPreviousMaid.push(createTimeSlotItem({ ...timeSlot, maid_id }))
          );
        });
      }
      return bookingCalendarFromPreviousMaid.sort((a, b) => a.number - b.number);
    },
  },
};
