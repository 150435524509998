<template>
  <div style="position: relative" :class="avatarSize">
    <b-img alt="avatar" :src="imgUrl" class="avatar" :class="imgSize" @click="$emit('click')" @error="(e) => (e.target.src = require('@/assets/images/preview-upload.png'))" />
    <div class="badge">
      <b-img
        style="cursor: default"
        @click.stop
        v-b-tooltip.hover.html="$t('form_label.badge_level')"
        alt="badge"
        :src="badgeImg"
        v-if="!!badgeImg"
      />
    </div>
  </div>
</template>

<script>
import avatarDefault from '@/assets/images/avatar_default.jpg'
export default {
  props: {
    url: String,
    level: {
      default: 'regular maid',
      type: String,
    },
    size: String,
  },
  computed: {
    imgUrl() {
      return this.url === 'https://graph.facebook.com/112847403474995/picture?type=large'
        ? avatarDefault
        : this.url
    },
    imgSize() {
      return !this.size ? '' : this.size
    },
    avatarSize() {
      return !this.size ? '' : `size-${this.size}`
    },
    badgeImg() {
      if (!this.level) return ``
      const level = this.level.toLowerCase()
      switch (level) {
        case 'quality maid':
          return require('../assets/images/badge/quality.svg')
        case 'top maid':
          return require('../assets/images/badge/top.svg')
        case 'super maid':
          return require('../assets/images/badge/super.svg')
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.booking {
  .badge {
    left: calc(72px - 28px);
  }
}
.badge {
  position: relative;
  width: 28px;
  height: 28px;
  margin-top: -28px;
  left: 65%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  img {
    border: none !important;
    height: 90% !important;
    width: auto !important;
    border-radius: 0 !important;
  }
  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
    margin-top: -24px;
    /* left: calc(40px - 18px); */
    right: 0;
  }
}
.avatar {
  object-fit: cover;
  object-position: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  border: 4px solid #fff;
  z-index: 9999;
}
.sm {
  width: 48px;
  height: 48px;
}
.md {
  width: 64px;
  height: 64px;
}
.lg {
  width: 84px;
  height: 84px;
}
.xl {
  width: 96px;
  height: 96px;
  border: 5px solid #fff;
}
.xxl {
  width: 128px;
  height: 128px;
  border: 5px solid #fff;
}

.size-xl {
  .badge {
    position: relative;
    width: 30px;
    height: 30px;
    top: auto;
    left: 65%;
  }
}
.size-xxl {
  .badge {
    position: relative;
    width: 36px;
    height: 36px;
    margin-top: -32px;
    top: auto;
    left: calc(80px);
  }
}
</style>
