import axios from "axios";
import { getBackendURL, getEnvName } from "../helpers/network";
import store from "../store";

const host = window.location.host;
const env = getEnvName(host);
const mapResponseData = new Map();

let axiosInstance = axios.create({
  baseURL: getBackendURL(env),
  timeout: 120000,
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    console.log(`✅ Request interceptors 
      URL: ${config.url}
      Request Body: ${JSON.stringify(config.data, null, 4)}
    `)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async function (response) {
    // Do something with response data
    // console.log('✅ Response interceptors', response)
    // await store.dispatch("auth/SHOWALERT", false);
    return response;
  },
  async function (error) {
    // Do something with response error
    let originalRequest = error.config;
    if (error.response && error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.message === "Network Error") {
      // alert("Network Error : Please check your internet connection");
      // await store.dispatch("auth/SHOWALERT", true);
      return Promise.reject(error);
    }

    await store.dispatch("auth/refreshToken");
    return Promise.reject(error);
  }
);

class HttpRequest {
  constructor() {
    this.axios = axios;
  }

  setHeader(header) {
    axiosInstance.defaults.headers.common[header.key] = header.value;
    axiosInstance.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  }

  async get(endpoint, data, config) {
    const conf = config.headers && config.headers.Authorization ? config.headers.Authorization : ''
    const key = `${endpoint}-${JSON.stringify(data)}-${JSON.stringify(conf)}`
    const cacheTime = 1000 * 10 // 10 seconds
    const currentTime = new Date().getTime()
    if (mapResponseData.has(key)) {
      const { timestamp } = mapResponseData.get(key)
      if (currentTime - timestamp < cacheTime) {
        // console.log('✅ Cache hit', endpoint)
        return Promise.resolve(mapResponseData.get(key).data)
      }
    }

    try {
      let res = await axiosInstance.get(endpoint, {
        params: data,
        ...config,
      })

      if (endpoint && !endpoint.includes('notification')) {
        mapResponseData.set(key, {
          data: res,
          timestamp: new Date().getTime(),
        })

        // console.log('✅ Cache miss', key)

        setTimeout(() => {
          mapResponseData.delete(key)
        }, cacheTime + 1000)
      }

      return res
    } catch (error) {
      console.error('❌ Error', error)
      return Promise.reject(error)
    }
  }

  post(endpoint, data, config) {
    return axiosInstance.post(endpoint, data, config);
  }

  delete(endpoint, data, config) {
    return axiosInstance.delete(endpoint, {
      data,
      ...config,
    });
  }

  request(type, url, data) {
    const prefix = "https://cors-anywhere.herokuapp.com/"; // temporary handle cors issue
    let promise = null;
    
    switch (type) {
      case "GET":
        promise = axios.get(`${prefix}${url}`, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PUT":
        promise = axios.put(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default HttpRequest;
