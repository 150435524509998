/* eslint-disable no-undef */
import ApiClient from '../services/'
import { UPDATE_AUTH_STATE, SET_SESSION, RESET_STATE } from './types/auth'
import { FACEBOOK_APP_ID_PRODUCTION, FACEBOOK_APP_ID_TEST } from '../config/constants'
import { isProduction } from '../utils'

const initAuthState = {
  callBackPathAfterWelcomePage: '',
  isSignUp: null,
  isLoginEmail: null,
  isResetPassword: null,
  emailReset: null,
  isConfirmPassword: null,
  isLoggedIn: false,
  isLineLogin: false,
  currentEmail: '',
  showAlert: false,

  fb: {
    accessToken: null,
    userID: null,
    expiresIn: null,
    reauthorize_required_in: null,
    signedRequest: null
  },
  session: {
    access_token: null,
    token_type: null,
    expires_in: null,
    refresh_token: null
  },
  isNewUser: false,
  isCurrent: null,
  isChangePassword: null
}

export default {
  namespaced: true,
  state: {
    ...initAuthState
  },
  mutations: {
    SHOWALERT(state, payload) {
      state.showAlert = payload
    },
    ISLINELOGIN(state, payload) {
      state.isLineLogin = payload
    },
    isEmailSignUp(state, payload) {
      if (payload && payload.error) {
        let split = payload.error.split(' ')
        state.isSignUp = split[0]
        console.log(split[0])
      } else {
        state.isSignUp = payload
      }
    },
    ISCHANGEEMAIL(state, payload) {
      if (payload && payload.error) {
        state.currentEmail = payload
      } else {
        state.currentEmail = payload
      }
    },
    ISCONFIRMPASSWORD(state, payload) {
      if (payload === 'home') {
        state.isConfirmPassword = null
      }
      // payload.email
      if (payload.email) {
        state.isConfirmPassword = 'yes'
        state.emailReset = payload.email
      } else {
        state.isConfirmPassword = payload.error
      }
    },
    ISCURRENTPASSWORD(state, payload) {
      if (payload && payload.error) {
        let split = payload.error.split(' ')
        state.isCurrent = split[0]
        console.log(split[0])
      } else {
        state.isCurrent = payload
      }
    },
    ISCHANGEPASSWORD(state, payload) {
      if (payload && payload.error) {
        let split = payload.error.split(' ')
        state.isChangePassword = split[0]
        console.log(split[0])
      } else {
        state.isChangePassword = payload
      }
    },
    isResetLinkSent(state, payload) {
      if (payload.error) {
        state.isResetPassword = payload.error
      } else if (payload.kind) {
        state.isResetPassword = payload
      }
      // console.log("sss", state.isResetPassword);
    },
    isEmailLogIn(state, payload) {
      if (payload && payload.error) {
        let split = payload.error.split(' ')
        state.isLoginEmail = split[0]
        console.log(split[0])
      } else {
        state.isLoginEmail = payload
      }
    },
    setCallBackPathAfterWelcomePage(state, payload) {
      state.callBackPathAfterWelcomePage = payload
    },
    [UPDATE_AUTH_STATE](state, payload) {
      if (payload.status === 'connected') {
        state.fb = { ...state.fb, ...payload.authResponse }
      } else {
        Object.assign(state, initAuthState)
      }
    },

    [SET_SESSION](state, payload) {
      const { access_token, expires_in, refresh_token } = payload
      state.session = { ...state.session, ...payload }
      const expiresAt = JSON.stringify(expires_in * 1000 + new Date().getTime())
      state.isLoggedIn = true
      window.localStorage.setItem('access_token', access_token)
      window.localStorage.setItem('refresh_token', refresh_token)
      window.localStorage.setItem('expires_at', expiresAt)
      window.localStorage.setItem('admin_token', payload.admin_token ? payload.admin_token : null)
      localStorage.isRefreshTokenProcess =false
    },
    [RESET_STATE](state) {
      Object.assign(state, initAuthState)
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('refresh_token')
      window.localStorage.removeItem('expires_at')
      window.localStorage.removeItem('user_profile')
      window.localStorage.removeItem('admin_token')
    },
    updateStates(state, payload) {
      for (let key in payload) {
        if (!payload.hasOwnProperty(key)) {
          return
        }
        state[key] = payload[key]
      }
    }
  },
  actions: {
    SHOWALERT({ commit }, payload) {
      commit('SHOWALERT', payload)
    },
    setAdminSession({ commit }, payload) {
      console.log("Set Admin Session", payload)
      commit(SET_SESSION, payload)
    },
    updateAuthState({ commit }, payload) {
      commit(UPDATE_AUTH_STATE, payload)
    },
    async updateSessionState({ commit, dispatch }, payload) {
      commit(SET_SESSION, payload) // update session data
      await dispatch('user/getUserProfile', null, { root: true }) // fetching user profile
    },
    async signOut({ commit, dispatch }) {
      try {
        // alert("sign out")
        await ApiClient.logout()
        // dispatch('sbUser/sbDisconnect', null, { root: true })
        // dispatch('sbChannel/sbResetChannel', null, { root: true })
        // dispatch('sbMessage/sbResetMessage', null, { root: true })
      } catch (e) {
        console.log(e.response)
      } finally {
        localStorage.isRefreshTokenProcess = true
        dispatch('user/resetUser', null, { root: true }) // clear user data
        commit(RESET_STATE)
        console.log('RESET')
      }
    },
    // Google Login
    async signInGoogle({ dispatch }, payload) {
      // console.log('sign in google user', payload.getId())
      // console.log(payload.getAuthResponse().id_token) //id_token
      // const id_token = payload.getAuthResponse().id_token
      const id_token = payload.id_token
      const result = await ApiClient.userRegister({
        google_access_token: id_token
      })
        .then(res => res)
        .catch(({ response }) => response)
      if (result.status === 400) {
        // this user already registed
        await dispatch('getOAuthToken', {
          username: payload.sub,
          password: id_token
        })
      } else if (result.data) {
        // register success => get token
        const { fb_id, fb_token } = result.data
        await dispatch('getOAuthToken', {
          username: fb_id,
          password: fb_token
        })
      }
    },
    async signInLine({ commit, dispatch }, payload) {
      console.log('signIn line', payload)
      // console.log("line token", localStorage.lineToken);
      const result = await ApiClient.userRegister({
        line_access_token: payload.accessToken,
        line_id_token: payload.line_id_token
      })
        .then(res => res)
        .catch(({ response }) => response)
      if (result.status === 400) {
        // this user already registed
        await dispatch('getOAuthTokenLine', {
          username: payload.lineId,
          password: payload.accessToken
        })
      } else if (result.data) {
        // register success => get token
        const { fb_id, fb_token } = result.data
        await dispatch('getOAuthToken', {
          username: fb_id,
          password: fb_token
        })
        // commit("updateStates", { isNewUser: true });
      }
    },
    async signIn({ commit, dispatch }) {
      console.log('sign in');
    
      // Check if FB is already initialized
      if (!window.FB) {
        // Load the Facebook SDK asynchronously
        (function(d){
          var js, id = 'facebook-jssdk';
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement('script');
          js.id = id;
          js.async = true;
          js.src = "//connect.facebook.net/en_US/all.js";
          d.getElementsByTagName('head')[0].appendChild(js);
        })(document);
    
        // Initialize FB asynchronously after loading
        window.fbAsyncInit = function() {
          FB.init({
            appId: isProduction() ? FACEBOOK_APP_ID_PRODUCTION : FACEBOOK_APP_ID_TEST,
            status: true,
            cookie: true,
            oauth: true,
            xfbml: true,
            version: 'v17.0'
          });
          FB.apiDidInit = true;
    
          // Now that FB is initialized, call FB.login
          doLogin();
        };
      } else {
        // FB is already initialized, call FB.login directly
        doLogin();
      }
    
      // Function to handle the login process
      function doLogin() {
        if (window.FB.apiDidInit) {
          // FB is initialized, call FB.login
          FB.login(
            async (response) => {
              console.log('Getting callback from facebook')
              commit('UPDATE_AUTH_STATE', response)
              const { authResponse } = response
              if (authResponse) {
                const result = await ApiClient.userRegister({
                  fb_access_token: authResponse.accessToken,
                })
                  .then((res) => res)
                  .catch(({ response }) => response)
                if (result.status === 400) {
                  // this user already registed
                  await dispatch('getOAuthToken', {
                    username: authResponse.userID,
                    password: authResponse.accessToken,
                  })
                } else if (result.data) {
                  // register success => get token
                  const { fb_id, fb_token } = result.data
                  await dispatch('getOAuthToken', {
                    username: fb_id,
                    password: fb_token,
                  })
                  // commit("updateStates", { isNewUser: true });
                }
              }
            },
            { scope: 'public_profile,email' }
          )
        } else {
          // FB is not yet initialized, wait for it to be initialized
          setTimeout(doLogin, 100);
        }
      }
    },
    async signUpEmail({ commit, dispatch }, payload) {
      // console.log("payload", payload);
      let isError = false
      const result = await ApiClient.userRegister({
        ...payload
      })
        .then(res => res)
        .catch(({ response }) => {
          isError = true
          return response
        })
      if (result.data.error) {
        // await dispatch("logInEmail", {
        //   email: payload.email,
        //   password: payload.password,
        // });
        commit('isEmailSignUp', result.data)
      } else {
        await dispatch('logInEmail', {
          email: payload.email,
          password: payload.password
        })
        commit('isEmailSignUp', result.data)
      }
    },
    async checklogInEmail({ commit, dispatch }, payload) {
      // console.log("payload", payload);
      const result = await ApiClient.logInEmail(payload)
        .then(res => res)
        .catch(({ response }) => {
          console.log('res', response)
          return response
        })
      // console.log('result.data', result.data)
      if (payload.isEmail) {
        result.data.isEmail = true
      }

      // if (result.data && result.status !== 400) {
      //   // this user already registed
      //   await dispatch("getOAuthToken", {
      //     username: result.data.localId,
      //     password: result.data.idToken,
      //   });
      //   // commit("updateStates", { isNewUser: true });
      // }

      commit('ISCURRENTPASSWORD', result.data)

      // if (result.status === 200) {
      //   commit(SET_SESSION, result.data);
      //   await dispatch("user/getUserProfile", null, { root: true });
      // }

      // console.log("result", result);
    },
    async changeEmail({ commit, dispatch }, payload) {
      // console.log("payload", payload);
      const result = await ApiClient.changeEmail(payload)
        .then(res => res)
        .catch(({ response }) => {
          console.log('res', response)
          return response
        })
      // console.log('result.data', result.data)
      commit('ISCHANGEEMAIL', result.data)
    },
    async changePassword({ commit, dispatch }, payload) {
      const result = await ApiClient.changePassword(payload)
        .then(res => res)
        .catch(({ response }) => {
          console.log('res', response)
          return response
        })
      // console.log('result.data', result.data)
      commit('ISCHANGEPASSWORD', result.data)
    },
    async logInEmail({ commit, dispatch }, payload) {
      // console.log("payload", payload);
      const result = await ApiClient.logInEmail(payload)
        .then(res => res)
        .catch(({ response }) => {
          console.log('res', response)
          return response
        })
      // console.log('result.data', result.data)
      if (result.data && result.status !== 400) {
        // this user already registed
        await dispatch('getOAuthToken', {
          username: result.data.localId,
          password: result.data.idToken
        })
        // commit("updateStates", { isNewUser: true });
      }

      commit('isEmailLogIn', result.data)

      // if (result.status === 200) {
      //   commit(SET_SESSION, result.data);
      //   await dispatch("user/getUserProfile", null, { root: true });
      // }

      // console.log("result", result);
    },
    async getOAuthToken({ commit, dispatch }, { username, password }) {
      const result = await ApiClient.oAuthToken({ username, password })
        console.log("access exprie at",result.data);
      commit(SET_SESSION, result.data)
      await dispatch('user/getUserProfile', null, { root: true })
    },
    async getOAuthTokenLine({ commit, dispatch }, { username, password }) {
      const result = await ApiClient.oAuthTokenLine({ username, password })
      // console.log("result", result);

      commit(SET_SESSION, result.data)
      await dispatch('user/getUserProfile', null, { root: true })
    },

    async refreshToken({ commit, dispatch }) {
      console.log('start refresh token')
      const refresh_token = window.localStorage.getItem('refresh_token')
      // console.log('sss',refresh_token);
      if (refresh_token) {
        try {
          const body = {
            grant_type:"refresh_token",
            client_id:"bluuu-client-id",
            client_secret:"bluuu-client-secret",
            refresh_token:refresh_token
          }
          // console.log('body refresh token',body);
          const result = await ApiClient.newToken(body)
          await commit(SET_SESSION, result.data)
         await dispatch('user/getUserProfile', null, { root: true })
          // await dispatch('user/getUserProfile', null, { root: true })
          return true
        } catch (e) {
          
          // alert(e.response.data.error)
          // // console.log(e.response.data)
      
          // for (let i =0 ; i<999 ; i++){ 
          //   if(localStorage.isRefreshTokenProcess === true){ 
            // setTimeout(()=>{
              if(localStorage.isRefreshTokenProcess === true){ 
                alert("logout");
                localStorage.isRefreshTokenProcess = false
                dispatch('user/resetUser', null, { root: true })
                dispatch('auth/signOut', null, { root: true })
                commit(RESET_STATE)}
            // },20000)
             
          //   }
          // }
          return false
         
 
        }
      }
    },


    async resetPasswordbyEmail({ commit, dispatch, state }, payload) {
      state.isResetLinkSent = null
      // console.log(payload);
      let x = false
      const result = await ApiClient.resetPasswordbyEmaill(payload)
        .then(res => res)
        .catch(({ response }) => {
          commit('isResetLinkSent', response.data)
          console.log(response)
          x = true
        })
      if (!x) {
        commit('isResetLinkSent', result.data)
      }
    },
    async confirmPassword({ commit, dispatch }, payload) {
      console.log(payload)
      const result = await ApiClient.confirmPassword(payload)
        .then(res => res)
        .catch(({ response }) => {
          // console.log("response",  response.data );
          commit('ISCONFIRMPASSWORD', response.data)
        })
      console.log('result', result)

      commit('ISCONFIRMPASSWORD', result.data)
    }
  },
  getters: {}
}