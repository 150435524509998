import Vue from 'vue'
import VueI18n from 'vue-i18n'
import thMessage from './locales/th.json'
import enMessage from './locales/en.json'

Vue.use(VueI18n)

const defaultLocale = window.localStorage.getItem('locale') || 'th'

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: { th: thMessage, en: enMessage }
})
