import ApiClient from '../services'

const initUser = {
  email: undefined,
  fb_id: undefined,
  fb_token: undefined,
  first_name: undefined,
  last_name: undefined,
  nick_name: undefined,
  id: undefined,
  image_url: undefined,
  maid: undefined,
  maid_id: undefined,
  role: undefined,
  phone: undefined,
  user_locations: undefined,
  is_verified_phone: undefined,
  send_email_before_booking: false,
  send_email_when_remaining_credits: false,
  dont_mind_let_fb_friends: false,
  location_history: [],
  accommodation_confirmed: false,
}

export default {
  namespaced: true,
  state: {
    ...initUser
  },
  mutations: {
    UPDATE_USER(state, payload) {
      Object.assign(state, payload)
    },
    RESET_USER(state) {
      Object.assign(state, initUser)
    },
    updateStates(state, payload) {
      for (let key in payload) {
        if (!payload.hasOwnProperty(key)) {
          return
        }
        state[key] = payload[key]
      }
    }
  },
  actions: {
    updateUser({ commit }, payload) {
      commit('UPDATE_USER', payload)
    },
    async getUserProfile({ commit, dispatch }) {
      try {
        const result = await ApiClient.userProfile()
        commit('UPDATE_USER', result.data)
        commit('auth/updateStates', { isLoggedIn: true }, { root: true })
      } catch (e) {
        console.log(e.response)
      }

      dispatch('fetchLocationHistory')
      // await dispatch('sbUser/sbConnect', null, { root: true })
      // await dispatch('sbChannel/sbGetGroupChannelList', null, { root: true })
    },
    resetUser({ commit }) {
      commit('RESET_USER')
    },
    async fetchLocationHistory({ commit }) {
      try {
        const result = await ApiClient.bookingLocationHistory()
        if (result) {
          const location_history = result.data.map(location => ({
            ...location,
            main_text: location.location_name,
            description: location.location_name,
            place_id: location.location_place_id,
            secondary_text: ''
          }))
          commit('updateStates', { location_history })
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    // fix me - remove if not used
    initUserProfileFromLocalStorage({ commit }) {
      const userProfileStr = window.localStorage.getItem('user_profile')
      if (userProfileStr) {
        const userProfileJSON = JSON.parse(userProfileStr)
        commit('UPDATE_USER', userProfileJSON)
      }
    },
    async updateUserProfile({ commit }, payload) {
      try {
        const result = await ApiClient.updateUserProfile(payload)
        if (result) {
          console.log(result.data)
          commit('UPDATE_USER', result.data)
          return true
        }
      } catch (e) {
        console.log(e.response)
        return false
      }
    },
    async updateUserFBImage({ commit }) {
      try {
        const result = await ApiClient.updateUserFBImage()
        if (result.data) {
          const { image_url } = result.data
          await commit('updateStates', { image_url })
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    async uploadPhotoFromFile({ commit }, payload) {
      try {
        const result = await ApiClient.uploadProfileImage(payload)
        if (result.data) {
          commit('UPDATE_USER', result.data)
        }
      } catch (e) {
        console.log(e.response)
      }
    },
    async updateUserAccommodation({ commit }) {
      try {
        const result = await ApiClient.getUserAccommodationConfirmation()
        if (result.data) {
          commit('updateStates', { accommodation_confirmed: true })
        }
      } catch (e) {
        console.log(e.response)
      }
    }
  },
  getters: {}
}
