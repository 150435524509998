import { i18n } from '../i18n'

export const BASE_URL = 'https://apistaging.bluuu.co'
export const UAT_URL = 'https://apistaging.bluuu.co'
export const PROD_URL = 'https://api.bluuu.co'

export const FACEBOOK_APP_ID_TEST = '175195313747932'
export const FACEBOOK_APP_ID_PRODUCTION = '302303440721203'

// Grant type
export const REFRESH_TOKEN = 'refresh_token'
export const CLIENT_CREDENTIALS = {
  client_id: 'bluuu-client-id',
  client_secret: 'bluuu-client-secret'
}
export const BASIC_AUTH_CREDENTIALS = {
  username: 'bluuu',
  password: 'uuubl'
}

export const RELEASE_DATE_TIME = '2020-03-09T17:00:01.000Z'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDFUalmOzv8LkFm-RySIeJXU7lsQ2lKEzo'

export const MERCHANT_ID = 45816072
export const PAYSOLUTION_URL = 'https://www.thaiepay.com/epaylink/payment.aspx'

export const getReturnURL = () => {
  // alert(window.location.origin + `/${i18n.locale}/payment/result`)
  return window.location.origin + `/${i18n.locale}/payment/result`
}
