export const isPlaceInAvailableArea = (place, availableArea) => {
  const { terms } = place
  return terms.some(term => {
    const subDistrict = term.value.toLowerCase()
    return availableArea.some(area => {
      const areaTH = area.name_th.toLowerCase()
      const areaEN = area.name_en.toLowerCase()
      return subDistrict.includes(areaTH) || subDistrict.includes(areaEN)
    })
  })
}

export const defaultMapsCenter = Object.freeze({ lat: 13.7506, lng: 100.5316 })
