import ApiClient from "../services";

const initValue = {
  detail: {
    id: undefined,
    location_name: undefined,
    description: undefined,
    rating: undefined,
    no_of_reviews: undefined,
    user: {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      fb_id: undefined,
      image_url: undefined,
      nick_name: undefined,
      phone: undefined,
      is_verified_phone: undefined,
      fb_token: undefined,
      role: undefined,
      maid_id: undefined,
    },
  },
  modal: {}, // use on review maid
  favoriteMaid: [],
  maids: [],
  maidsFBFriend: [],
  maidsAdminRecommend: [],
  maidsCombined: [], // maids + maidsFBFriend
  maidHiddenIDList: [], // list of maid's id (แม่บ้านที่ต้องการซ่อนในหน้า /maids, /select-maids)
  totalMaids: 0, // total maid from backend (fix me - remove)
  totalMaidsFriend: 0, // same as above
  isFetchingMaids: false,
  isFetchingMaidsFriend: false,
  isAllMaidsFetched: false, // ดึงครบทุกหน้าแล้วหรือยัง
  isAllMaidsFriendFetched: false,
  recentlyUnFavMaids: [],
  isUpdatingFavMaid: false,
  maidsObject: {}, // key = maid_id, value: response from api,
  isShowModal: false,
};

export default {
  namespaced: true,
  state: {
    ...initValue,
  },
  mutations: {
    UPDATE_MAID_DETAIL(state, payload) {
      // Object.assign(state.detail, payload);
      const temp = { ...state.detail, ...payload };
      state.detail = temp;
    },
    updateStates(state, payload) {
      for (let key in payload) {
        if (!payload.hasOwnProperty(key)) {
          return;
        }
        state[key] = payload[key];
      }
    },
    resetState(state) {
      Object.assign(state, initValue);
    },
    addMaidDetail(state, payload) {
      state.maidsObject = { ...state.maidsObject, [payload.id]: payload };
    },
    addMaids(state, payload) {
      state.maids = [...state.maids, ...payload];
    },
    addMaidsFriend(state, payload) {
      state.maidsFBFriend = [...state.maidsFBFriend, ...payload];
    },
    setMaidAdminRecommend(state, payload) {
      state.maidsAdminRecommend = payload
    },
    resetMaidsList(state) {
      state.maids = [];
      state.maidsFBFriend = [];
      state.maidsAdminRecommend = [];
      state.isAllMaidsFetched = false;
      state.isAllMaidsFriendFetched = false;
    },
    resetMaidHiddenList(state) {
      state.maidHiddenIDList = [];
    },
    resetMaidModal(state) {
      state.detail = {
        id: undefined,
        location_name: undefined,
        description: undefined,
        rating: undefined,
        no_of_reviews: undefined,
        user: {
          id: undefined,
          first_name: undefined,
          last_name: undefined,
          email: undefined,
          fb_id: undefined,
          image_url: undefined,
          nick_name: undefined,
          phone: undefined,
          is_verified_phone: undefined,
          fb_token: undefined,
          role: undefined,
          maid_id: undefined,
        },
      };
    },
    addRecentlyUnFavMaids(state, payload) {
      state.recentlyUnFavMaids = [...state.recentlyUnFavMaids, payload];
    },
    resetRecentlyUnFaveMaids(state) {
      state.recentlyUnFavMaids = [];
    },
    removeRecentlyUnFavMaid(state, maidId) {
      state.recentlyUnFavMaids = [
        ...state.recentlyUnFavMaids.filter((maid) => maid.id !== maidId),
      ];
    },
    setMaidHiddenIDList(state, payload) {
      state.maidHiddenIDList = payload;
    },
    setIsShowModal(state, payload) {
      state.isShowModal = payload;
    },
  },
  actions: {
    addHiddenMaid({ commit, state }, maid_id) {
      const hiddenList = state.maidHiddenIDList.filter((id) => id !== maid_id);
      hiddenList.push(maid_id);
      commit("setMaidHiddenIDList", [maid_id]);
    },
    setShowMaidModal({ commit }, payload) {
      commit("UPDATE_MAID_DETAIL", payload);
    },
    async fetchMyFavoriteMaids({ commit }) {
      try {
        const result = await ApiClient.myFavoriteMaid();
        if (result) {
          const maids = result.data.maids.filter((maid) => maid.user !== null);
          commit("updateStates", {
            favoriteMaid: maids,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async unSaveToFavMaid({ dispatch, commit }, payload) {
      // commit('updateStates', { isUpdatingFavMaid: true })
      try {
        const result = await ApiClient.unSaveToFavMaid(payload);
        if (result.data) {
          dispatch("fetchMyFavoriteMaids");
        }
        // commit('updateStates', { isUpdatingFavMaid: false })
        return true;
      } catch (e) {
        // commit('updateStates', { isUpdatingFavMaid: false })
        console.log(e.response);
        return false;
      }
    },
    async saveToFavMaid({ dispatch, commit }, payload) {
      // commit('updateStates', { isUpdatingFavMaid: true })
      try {
        const result = await ApiClient.saveToFavMaid(payload);
        if (result.data) {
          dispatch("fetchMyFavoriteMaids");
          commit("removeRecentlyUnFavMaid", payload);
        }
        // commit('updateStates', { isUpdatingFavMaid: false })
        return true;
      } catch (e) {
        // commit('updateStates', { isUpdatingFavMaid: false })
        console.log(e.response);
        return false;
      }
    },
    async getMaids({ commit, state }, { options }) {
      if (!state.isAllMaidsFetched) {
        commit("updateStates", {
          isFetchingMaids: true,
          isFetchingMaidsFriend: false,
        });
        try {
          const result = await ApiClient.maidList(options);
          if (result.data) {
            const { item_count, page_count, maids } = result.data;
            console.log("maid list",result.data);
            const newMaids = maids.filter((maid) => maid.user !== null)
            commit("addMaids", newMaids);
            commit("updateStates", {
              isFetchingMaids: false,
              totalMaids: item_count,
              isAllMaidsFetched: maids.length === 0,
            });
            return { page_count };
          }
        } catch (error) {
          console.error(error);
          return { page_count: null };
        }
      }
      return { page_count: null };
    },
    async getMaidsFriend({ commit, state }, { options }) {
      if (!state.isAllMaidsFriendFetched) {
        commit("updateStates", {
          isFetchingMaidsFriend: true,
          isFetchingMaids: false,
        });
        try {
          const result = await ApiClient.maidListWithFriend(options);
          if (result.data) {
            const { item_count, page_count, maids } = result.data;
            const newMaids = maids.filter((maid) => maid.user !== null);
            commit("addMaidsFriend", newMaids);
            commit("updateStates", {
              isFetchingMaidsFriend: false,
              totalMaidsFriend: item_count,
              isAllMaidsFriendFetched: maids.length === 0,
            });
            return { page_count };
          }
        } catch (error) {
          return { page_count: null };
        }
      }
      return { page_count: null };
    },
    async getMaidsAdminRecommend({ commit }, { options, isLoggedIn }) {
      console.log('getMaidsAdminRecommend', options, isLoggedIn);
      try {
        const result = await ApiClient.maidListAdminRecommend(options, isLoggedIn);
        if (result.data) {
          const { maids } = result.data
          const newMaids = maids.filter((maid) => maid.user !== null)
          commit('setMaidAdminRecommend', newMaids)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getMaidByMaidId({ commit, state }, maidId) {
      if (state.maidsObject.hasOwnProperty(maidId)) return;
      try {
        const result = await ApiClient.maidDetail(maidId);
        commit("addMaidDetail", result.data);
      } catch (e) {
        console.log(e.response);
      }
    },
    async inquiryMaidOutOfRadius(ctx, {
      latitude = 0,
      longitude = 0,
      maidId,
    }) {
      if (latitude && longitude && maidId) {
        try {

          const queryParams = {
            location_latitude: latitude,
            location_longitude: longitude,
            maid_id: maidId
          }
          console.log("queryParams", queryParams)

          const result = await ApiClient.maidList(queryParams)
          const isFoundMaid = Boolean(result.data.maids.find(({ id }) => id === maidId))
          console.log('isFoundMaid', isFoundMaid)
          // if maid not found in result list => no maid available for this lat/long
          return !isFoundMaid
          
        } catch (error) {
          return Promise.reject(false) // user location is not out of maid's radius
        }
      }
    }
  },
  getters: {
    totalMaids({ totalMaids }) {
      return totalMaids
    },
    totalMaidsFriend({ totalMaidsFriend }) {
      return totalMaidsFriend
    },
    maids({ maids, maidHiddenIDList }) {
      return maids.filter(({ id }) => {
        return maidHiddenIDList.indexOf(id) === -1;
      });
    },
    maidsFBFriend({ maidsFBFriend, maidHiddenIDList }) {
      return maidsFBFriend.filter(({ id }) => {
        return maidHiddenIDList.indexOf(id) === -1;
      });
    },
    maidsAdminRecommend({ maidsAdminRecommend, maidHiddenIDList }) {
      return maidsAdminRecommend.filter(({ id }) => {
        return maidHiddenIDList.indexOf(id) === -1;
      });
    },
  },
};
