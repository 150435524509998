import { i18n } from '../i18n'
import logo from '@/assets/logo.jpeg'

export default {
  namespaced: true,
  state: {
    meta: {
      title: '',
      description: '',
      metaTags: [
        { name: 'description', content: '' },
        { property: 'og:url', content: 'https://bluuu.co' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: '' },
        { property: 'og:description', content: '' },
        { property: 'og:image', content: 'https://dev.houseofdev.tech/assets/images/logo_bluuu.jpg' },
      ],
    },
  },
  getters: {
    GET_METATAGS(state) {
      // context.commit('SET_TITLE', 'home')
      // console.log(state.metaTags)
      console.log(state.meta)
      return state.meta
    },
  },
  mutations: {
    SET_METATAGS(state, payload) {
      state.meta.metaTags[1].content = 'https://bluuu.co' + payload.path
      state.meta.title = i18n.t('pages.meta.' + payload.name + '.title')
      state.meta.metaTags[0].content = i18n.t('pages.meta.' + payload.name + '.description')
      state.meta.metaTags[3].content = i18n.t('pages.meta.' + payload.name + '.title')
      state.meta.metaTags[4].content = i18n.t('pages.meta.' + payload.name + '.description')
    },
  },
  actions: {
    async SET_META(context, payload) {
      console.log(payload) //home
      await context.commit('SET_METATAGS', payload)
      return (context.getters.GET_METATAGS)
    },
  },
}
