<template>
  <b-modal
    :id="modalID"
    hide-header
    hide-footer
    :centered="centered"
    :size="modalSize"
    @shown="onShown"
    :scrollable="scrollable"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    @hide="closeModal"
    modal-header-close
  >
    <div class="d-flex justify-content-end" v-if="!hideCloseButton">
      <i class="fas fa-times close-btn" @click="closeModal"></i>
    </div>
    <div class="mx-2 mb-2">
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    closeModal() {
      console.log('nope')
      this.$store.commit('setIsSignUpShouldnull', true)
      this.$root.$emit('bv::hide::modal', this.modalID)
      this.$emit('close')
    },
    onShown() {
      this.$emit('shown')
      this.$store.commit('setIsSignUpShouldnull', false)
    },
  },
  props: {
    size: {
      type: String,
      default: 'md',
    },
    id: {
      type: String,
      default: 'base-modal',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    centered: { type: String },
  },
  computed: {
    modalSize() {
      return this.size ? this.size : 'md'
    },
    modalID() {
      return this.id ? this.id : 'base-modal'
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  color: red;
}
.close-btn {
  font-size: 1.3em;
  cursor: pointer;
  margin-top: -0.3em;
  margin-right: -0.2em;
}
</style>
