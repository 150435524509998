<template>
  <div>
    <ReviewItem :review="review" @delete-review="deleteReview" />
    <div class="div-plus" v-if="hasSubReviews">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="30"
        fill="currentColor"
        class="bi bi-plus plus"
        viewBox="0 0 16 16"
        @click="isExpand = true"
        v-if="!isExpand"
      >
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="30"
        fill="currentColor"
        class="bi bi-dash plus"
        viewBox="0 0 16 16"
        @click="isExpand = false"
        v-if="isExpand"
      >
        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
      </svg>
    </div>
    <div class="div-plus" style="margin-bottom: 1px" v-else>
      <br />
    </div>

    <div v-if="isExpand">
      <div class="pl-4 comment_sub" v-for="subReview in subReviews" :key="subReview.id">
        <ReviewItem :review="subReview" @delete-review="deleteReview" />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import ReviewItem from './ReviewItem'
export default {
  components: { ReviewItem },
  data: () => ({
    isExpand: false,
  }),
  props: {
    review: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['delete-review'],
  computed: {
    subReviews: function () {
      return get(this.review, 'reivews', [])
    },
    hasSubReviews: function () {
      return get(this.review, 'reivews', []).length > 0
    },
  },
  methods: {
    deleteReview() {
      this.$emit('delete-review')
    },
  },
}
</script>

<style scoped>
.div-plus {
  text-align: end;
  position: relative;
  bottom: 0px;
}
.div-plus svg:hover {
  cursor: pointer;
}
/* @media screen and (max-width: 500px) {
  .div-plus {
    text-align: end;
    position: relative;
    bottom: 0px;
  }
} */
</style>
