<template>
  <BaseModal
    id="require-auth-modal"
    :noCloseOnBackdrop="false"
    :noCloseOnEsc="true"
    :hideCloseButton="false"
    @close="goBack"
  >
    <div v-if="!isForgot">
      <div class="justify-content-center d-flex">
        <div class="page-header-text top10" style="top: 10px">
          <h4 class="mb-3 w380">{{ $t('warning.sign_in') }}</h4>
        </div>
      </div>
      <div class="text-center px-2">
        <p class="my-3">
          {{ $t('warning.please_sign_in_to_make_bookings') }}
          {{ $t('warning.by_signing_up_terms') }}
          <a target="blank" :href="hostUrl + 'terms'">{{ $t('warning.terms_condition') }}</a>

          <!-- {{ $t('warning.and') }} -->,
          <a target="blank" :href="hostUrl + 'privacy-policy'">{{ $t('warning.policy') }}</a>
          <span v-if="$i18n.locale === 'en'">, {{ $t('warning.and') }} </span>
          <span v-if="$i18n.locale === 'th'"> {{ $t('warning.and') }} </span>

          <a target="blank" :href="hostUrl + 'user-data-deletion-instructions'">{{
            $t('warning.user_deletion')
          }}</a>
          <span v-if="$i18n.locale === 'th'"> {{ $t('warning.by_bluuu') }}</span>
          <span v-if="$i18n.locale === 'en'">.</span>
        </p>

        <!-- <div> <b-badge variant="primary"></b-badge></div> -->

        <!-- <i class="bi bi-facebook"></i> -->
        <b-button class="btn-fb my-3 w380" size="lg" @click="doFBSignIn">
          <i class="fab fa-facebook-square fa-lg float-left m-1 mt-2 mr-2"></i>
          {{ $t('warning.log_in_with_facebook') }}</b-button
        >
        <br />
        <!-- <GoogleLogin
          :params="params"
          class="btn btn-google w380 my-3 btn-secondary btn-lg"
          :onSuccess="doGoogleSignIn"
        >
          <img
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            class="fa-lg float-left m-1 mr-2"
            style="background-color: white; height: 1em; border-radius: 3px"
          />
          {{ $t('warning.log_in_with_google') }}</GoogleLogin
        > -->
        <div class="btn btn-google w380 my-3 btn-secondary btn-lg" style="position: relative;">
          <img
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            class="fa-lg float-left m-1 mr-2"
            style="background-color: white; height: 1em; border-radius: 3px"
          />
          {{ $t('warning.log_in_with_google') }}
          <div id="google-login-btn" v-google-identity-login-btn="{ clientId:params.client_id,width: 400  }"></div>
        </div>
        <br />
        <b-button class="btn-line my-3 w380" size="lg" id="line_login" @click="doLineSignIn">
          <i class="fab fa-line fa-lg float-left m-1 mt-2 mr-2"></i>
          {{ $t('warning.log_in_with_line') }}</b-button
        >
        <br />
        <div class="my-4">
          <h4>{{ $t('warning.sign_up_with_email') }}</h4>
          <b-tabs
            content-class="mt-3"
            active-nav-item-class="font-weight-bold  text-dark"
            justified
            class="my-4"
          >
            <b-tab :title="$t('warning.sign_up')" :active="$route.name === 'BookingReview'">
              <b-form @submit="doSignUpEmail">
                <b-form-group>
                  <b-form-input
                    v-model="signUp.email"
                    type="email"
                    :placeholder="$t('warning.email')"
                    @change="notDisable"
                    size="default"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="signUp.first_name"
                    type="text"
                    :placeholder="$t('warning.first_name')"
                    @change="notDisable"
                    size="default"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="signUp.last_name"
                    type="text"
                    :placeholder="$t('warning.last_name')"
                    size="default"
                    @change="notDisable"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="signUp.password"
                    :type="type"
                    :placeholder="$t('warning.password')"
                    size="default"
                    @change="notDisable"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="signUp.confirm_password"
                    @change="notDisable"
                    :type="type"
                    :placeholder="$t('warning.confirm_password')"
                    size="default"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <p class="text-danger my-3" v-if="isSignUp && isSignUp === 'WEAK_PASSWORD'">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.password_should_be_at_least_6_characters') }}
                </p>
                <p class="text-danger my-3" v-if="isSignUp && isSignUp === 'EMAIL_EXISTS'">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.email_has_already_been_use') }}
                </p>
                <p class="text-danger my-3" v-if="isPasswordNotMatch">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.password_doesnt_match') }}
                </p>
                <b-button type="submit" :disabled="signup_disable" class="btn-email w380" size="lg">
                  {{ $t('warning.sign_up') }}</b-button
                >
              </b-form>
            </b-tab>
            <b-tab :title="$t('warning.log_in')" :active="$route.name !== 'BookingReview'">
              <b-form @submit="dologInEmail">
                <b-form-group>
                  <b-form-input
                    @change="loginSwitch"
                    v-model="logIn.email"
                    type="email"
                    :placeholder="$t('warning.email')"
                    size="default"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    @change="loginSwitch"
                    v-model="logIn.password"
                    :type="type"
                    :placeholder="$t('warning.password')"
                    size="default"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <p
                  class="text-danger my-3 text-left"
                  v-if="isLoginEmail && isLoginEmail === 'TOO_MANY_ATTEMPTS_TRY_LATER'"
                >
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.try_many') }}
                </p>

                <p
                  class="text-danger my-3 text-left"
                  v-if="isLoginEmail && isLoginEmail === 'EMAIL_NOT_FOUND'"
                >
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.this_email_address_was_not_found') }}
                </p>

                <p
                  class="text-danger my-3"
                  v-if="isLoginEmail && isLoginEmail === 'INVALID_PASSWORD'"
                >
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ $t('warning.please_check_your_password') }}
                </p>

                <b-button type="submit" :disabled="login_disable" class="btn-email w380" size="lg">
                  {{ $t('warning.log_in') }}</b-button
                >
              </b-form>
            </b-tab>
          </b-tabs>
          <b-row class="px-2 justify-content-center">
            <div>
              <u class="cursor-point text-primary" @click="isForgot = true">
                {{ $t('warning.forgot_password') }}
              </u>
            </div>

            <!-- <div class="col-4">
              <p class="my-3 cursor-point text-primary">
                {{ $t("warning.reset") }}
              </p>
            </div> -->
          </b-row>
        </div>
        <!-- <p class="my-3">{{ $t("warning.more_options_coming_soon") }}</p> -->
        <!-- <b-button
          variant="outline-light"
          v-if="!isUat"
          class="btn-comin my-1 w380"
          >{{ $t("warning.log_in_with_email") }}</b-button
        > -->
        <!-- <br v-if="!isUat" /> -->
        <!-- <b-button
          variant="outline-light"
          class="btn-comin my-1 w380"
          v-if="!isUat"
          >{{ $t("warning.log_in_with_line") }}</b-button
        > -->
        <!-- <br v-if="!isUat" />
        <b-button variant="outline-light" class="btn-comin my-1 w380">{{
          $t("warning.log_in_with_google")
        }}</b-button> -->
      </div>
    </div>
    <div v-else style="height: 820px">
      <div class="justify-content-center d-flex">
        <div class="page-header-text top10" style="top: 10px">
          <h4 class="mb-3 w380">{{ $t('warning.reset_password') }}</h4>
        </div>
      </div>
      <div v-if="!isSendAgain" class="justify-content-center">
        <div class="my-3 text-center">
          <p>{{ $t('warning.please_enter_the_email_address') }}</p>
        </div>
        <div class="mt-5 px-4">
          <b-form @submit="sentEmailToReset" class="justify-content-center text-center">
            <b-form-group>
              <b-form-input
                v-model="emailReset"
                type="email"
                :placeholder="$t('warning.email')"
                required
                size="default"
              ></b-form-input>
              <p
                class="text-danger my-3 text-left"
                v-if="isResetPassword && isResetPassword === 'EMAIL_NOT_FOUND'"
              >
                <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                {{ $t('warning.this_email_address_was_not_found') }}
              </p>
              <p
                class="text-danger my-3 text-left"
                v-if="isResetPassword && isResetPassword === 'RESET_PASSWORD_EXCEED_LIMIT'"
              >
                <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                {{ $t('warning.email_limit') }}
              </p>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="btn-primary my-1 w380"
              :disabled="disable"
              >{{ $t('warning.reset_password') }}</b-button
            >
            <br />
            <b-button @click="goBack" variant="outline-light" class="btn-comin my-1 w380">{{
              $t('button.go_back')
            }}</b-button></b-form
          >
        </div>
      </div>
      <div v-else>
        <div class="my-5 text-center mb-4">
          <p>
            {{ $t('warning.the_link_has_been_sent') }}
          </p>

          <p v-if="count >= 2">{{ $t('warning.reset_link_was_resent') }}</p>
        </div>
        <div class="my-5 text-center">
          <u
            class="cursor-pointer text-primary mt-5"
            style="cursor: pointer"
            @click="sentEmailToReset"
            v-if="countDown <= 0 || (cooldown && count < 5)"
            :disabled="disable"
          >
            {{ $t('warning.send_email_again') }}
          </u>
          <u
            class="cursor-pointer text-primary mt-5"
            style="cursor: pointer; color: #afb1b0 !important"
            disabled
            v-else
          >
            {{ $t('warning.send_email_again') }}
          </u>

          <br />
          <b-button
            @click="isSendAgain = false"
            variant="outline-light"
            class="btn-comin mt-3 w380"
            >{{ $t('button.go_back') }}</b-button
          >
        </div>
      </div>
    </div>
  </BaseModal>
</template>
<script>
import BaseModal from './BaseModal'
import { createNamespacedHelpers } from 'vuex'
import { mapState } from 'vuex'
const AuthStore = createNamespacedHelpers('auth')
import liff from '@line/liff'
// import GoogleLogin from 'vue-google-login'
import GoogleSignInButton from 'vue-google-identity-login-btn';

export default {
  directives: {
    GoogleSignInButton
  },
  data() {
    return {
      params: {
        client_id: null,
      },
      line_login_click: false,
      isUat: false,
      uatHosts: [
        'uat.bluuu.co',
        'bluuu-demo.web.app',
        'localhost',
        '192.168.1.108',
        '192.168.1.110',
        '192.168.43.33',
        '192.168.0.201',
        '192.168.0.203',
      ],
      signUp: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        confirm_password: null,
        language: this.$i18n.locale,
      },
      logIn: {
        email: null,
        password: null,
      },
      type: 'password',
      isForgot: false,
      emailReset: null,
      isSendAgain: false,
      cooldown: false,
      countDown: 5,
      disable: false,
      signup_disable: false,
      login_disable: false,
      resetStatus: null,
      isPasswordNotMatch: false,
      count: 0,
    }
  },

  mounted() {
    // console.log("this.$i18n.local", this.$i18n.locale);
    // console.log('this.router', this.$route)
    let host = window.location.href
    if (this.uatHosts.some((h) => host.includes(h))) {
      this.isUat = true
      this.params.client_id =
        '771887845236-tqkotasnan3f5t5emrdof9da9ce664vp.apps.googleusercontent.com'
    } else {
      this.isUat = false
      this.params.client_id =
        '876686123235-1eeg7j73a75a8qr4rk1610seojf011iv.apps.googleusercontent.com'
    }
    this.isEmailSignUp(null)
    this.isEmailLogIn(null)
    this.isResetLinkSent({ kind: '-' })

    // console.log("isResetPassword", this.isResetPassword);
    // console.log("this.isUat", this.isUat);
    // this.isUat ? (localStorage.redirectTo = this.$route.path) : null;
    // console.log("localStorage.redirectTo modal", localStorage.redirectTo);

    // console.log("this.isSignUp", this.isSignUp);

    // console.log("isUATEnv", isUATEnv);
  },
  components: {
    BaseModal,
    // GoogleLogin,
  },
  methods: {
    ...AuthStore.mapActions([
      'signOut',
      'signIn',
      'signInGoogle',
      'signUpEmail',
      'logInEmail',
      'resetPasswordbyEmail',
      'confirmPassword',
    ]),

    ...AuthStore.mapMutations(['isEmailSignUp', 'isEmailLogIn', 'isResetLinkSent']),
    async doFBSignIn() {
      console.log('doFBSignIn')
      await this.signIn()
    },
    async onGoogleAuthSuccess (jwtCredentials) {
      // console.log(jwtCredentials);
      const profileData = JSON.parse( atob(jwtCredentials.split('.')[1]) );
      // console.log(profileData);
      await this.signInGoogle({...profileData,id_token:jwtCredentials})
    },
    async doGoogleSignIn(googleUser) {
      await this.signInGoogle(googleUser)
    },
    doLineSignIn() {
      let line_login = document.getElementById('line_login')
      if (!this.line_login_click) {
        this.line_login_click = true
        if (this.line_login_click) {
          line_login.click()
        }
      } else {
        this.line_login_click = false
      }

      let host = window.location.href
      let liffId = null

      if (this.uatHosts.some((h) => host.includes(h))) {
        liffId = '1655557892-VZMnBojD'
        // this.$router.push({ name: 'Home' });
      } else {
        liffId = '1655557900-GBd2VYj9'
      }

      // console.log("this.$router", this.$router.history.current.path);

      localStorage.redirectTo = this.$router.history.current.fullPath

      liff
        .init({
          liffId: liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login()
          } else {
            liff.logout()
          }
        })
    },
    doSignUpEmail(e) {
      this.signup_disable = true
      console.log('this.signUp.password', this.signUp.password, this.signUp.confirm_password)
      e.preventDefault()
      if (this.signUp.password === this.signUp.confirm_password) {
        this.isPasswordNotMatch = false
        this.signUpEmail(this.signUp)
      } else {
        this.isPasswordNotMatch = true
      }

      // await this.logInEmail({
      //   email: this.signUp.email,
      //   password: this.signUp.password,
      // });
    },
    dologInEmail(e) {
      this.login_disable = true
      e.preventDefault()
      this.logInEmail(this.logIn)
    },
    notDisable() {
      // console.log(this.signUp);
      this.signup_disable = false
      this.isEmailSignUp(null)
      this.isPasswordNotMatch = false
    },
    async sentEmailToReset(e) {
      this.count += 1
      this.disable = true
      e.preventDefault()
      // await this.isResetLinkSent(null);

      await this.resetPasswordbyEmail({
        email: this.emailReset,
        lang: this.$i18n.locale,
      })
      // this.countDownTimer();
      // this.isSendAgain = true;
    },
    goBack() {
      // this.isSendAgain = false;
      this.isForgot = false
    },
    loginSwitch() {
      this.login_disable = false
      this.isEmailLogIn(null)
    },
    countDownTimer() {
      this.cooldown = false
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.countDown = 10
        this.cooldown = true
        this.disable = false
      }
    },
  },
  computed: {
    ...AuthStore.mapState([
      'isLoggedIn',
      'isSignUp',
      'isLoginEmail',
      'isConfirmPassword',
      'isResetPassword',
    ]),

    ...mapState(['router', 'isSignUpShouldnull']),
    hostUrl() {
      if (this.isUat) {
        return 'https://uat.bluuu.co/'
      } else {
        return 'https://bluuu.co/'
      }
    },
  },
  watch: {
    emailReset(newValue) {
      this.disable = false
    },
    isLoggedIn(newValue, oldValue) {
      // console.log("newValue", newValue);
      newValue ?       
      setTimeout(() => {
        this.$root.$emit('bv::hide::modal', 'require-auth-modal')
      }, 500) : null
    },
    isSignUp(newValue, oldValue) {
      console.log('newValue', newValue)
      this.signup_disable = false
    },
    isLoginEmail(newValue, oldValue) {
      console.log('newValue', newValue)
      this.login_disable = false
    },
    router(newValue) {
      // console.log("newValue", newValue);
      // this.isUat ? (localStorage.redirectTo = newValue.from.path) : null;
      // console.log("redirect To ", localStorage.redirectTo);
    },
    isSignUpShouldnull(newValue) {
      if (newValue) {
        this.signUp = {
          email: null,
          first_name: null,
          last_name: null,
          password: null,
          confirm_password: null,
        }
        this.logIn = {
          email: null,

          password: null,
        }
      } else {
        null
      }
    },

    isResetPassword(newValue) {
      // console.log(
      //   "🚀 --------------------------------------------------------------------------"
      // );
      // console.log(
      //   "🚀 ~ file: SignInModal.vue ~ line 446 ~ sResetPassword ~ isPassword",
      //   newValue
      // );
      // console.log(
      //   "🚀 --------------------------------------------------------------------------"
      // );
      if (newValue && !newValue.kind) {
        if (newValue === 'EMAIL_NOT_FOUND') {
          this.isSendAgain = false
        } else {
          this.isSendAgain = true
          this.countDownTimer()
        }
      } else {
        if (newValue && newValue.kind !== '-') {
          this.isSendAgain = true
          this.countDownTimer()
        } else {
          this.isSendAgain = false
          this.resetStatus = newValue
          // console.log("status", this.resetStatus);
        }
      }
    },
  },
}
</script>
<style lang="scss">
#google-login-btn{
  position: absolute; 
  top: 0px; 
  right: 0px; 
  width: 100%; 
  height: 100%; 
  opacity: 0;
}
.btn-fb {
  background: #4267b2 !important;
  border-color: #4267b2 !important;
}
.btn-line {
  background: #00b900 !important;
  border-color: #00b900 !important;
}
.btn-email {
  background: #005dff !important;
  border-color: #005dff !important;
}
.btn-google {
  background: #4285f4 !important;
  border-color: #4285f4 !important;
}
.btn-comin {
  border-color: #6c757d !important;
  color: #6c757d !important;
}
.w380 {
  width: 100%;
}
.cursor-point {
  cursor: pointer !important;
}
@media (max-width: 767.98px) {
  .w380 {
    width: 300px !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 375px) {
  .w380 {
    width: 250px !important;
    font-size: 0.95rem !important;
  }
}
@media (max-width: 340px) {
  .w380 {
    width: 200px !important;
    font-size: 0.95rem !important;
  }
}
</style>
