import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./auth_module";
import UserModule from "./user_module";
import MaidModule from "./maid_module";
import BookingModule from "./booking_module";
import CalendarModule from "./calendar_module";

import sbUser from "./sbUser";
import sbChannel from "./sbChannel";
import sbMessage from "./sbMessage";

import ogMeta from "./ogMeta";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isSignUpShouldnull: null,
    images: [],
    router: {
      to: null,
      from: null,
    },
    showFullContentLoader: false,
    blockScroll: false,
    isLoading: false,
  },
  mutations: {
    setIsSignUpShouldnull(state, payload) {
      state.isSignUpShouldnull = payload;
    },
    setImages(state, payload) {
      state.images = payload;
    },
    setRouter(state, payload) {
      state.router = payload;
    },
    setShowFullContentLoader(state, payload) {
      state.showFullContentLoader = payload;
    },
    setBlockScroll(state, payload) {
      state.blockScroll = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    showFullContentLoader({ commit }, isShow) {
      commit("setBlockScroll", isShow);
      commit("setShowFullContentLoader", isShow);
    },
  },
  modules: {
    user: UserModule,
    auth: AuthModule,
    maid: MaidModule,
    booking: BookingModule,
    calendar: CalendarModule,
    sbUser,
    sbChannel,
    sbMessage,
    ogMeta,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["auth", "user", "calendar", "booking", "maid"],
      key: "bluuu",
    }),
  ],
});
