<template>
  <div id="app" :class="isPrerender ? 'blockScroll shimmer' : ''">
    <div :class="isPrerender ? 'shimmer-background' : ''"></div>
    <h1 style="display: none;">
      {{ $t('pages.meta.' + $route.name + '.description').includes('pages.meta') ? $t('pages.meta.index.description') : $t('pages.meta.' + $route.name + '.description') }}
    </h1>
    <h2 style="display: none;">
      Bluuu ช่วยให้คุณจองแม่บ้านคุณภาพดีได้ออนไลน์ในราคาสมเหตุสมผล
      Bluuu lets you book high quality maids online at a reasonable price
    </h2>
    <header>
      <nav-bar />
    </header>
    <main>
      <LoadingSpinner class="container-content card-container translateYg text-center" style="min-height: 50vh" v-if="$store.state.isLoading" />
      <router-view v-show="!$store.state.isLoading"  />
    </main>
    <footer>
      <b-footer />
    </footer>
    <maid-modal />
    <notifications position="top right" />
    <ImageViewer />
    <sign-in-modal></sign-in-modal>
    <BaseModal id="alert-network-error">
      <div class="text-center">
        <p>{{ $t('error.network_error') }}</p>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import BFooter from "./components/Footer";
import MaidModal from "./components/Modal/MaidDetail";
import SignInModal from "./components/Modal/SignInModal";
import ImageViewer from "./components/ImageViewer";
import LoadingSpinner from "./components/LoadingSpinner";
import BaseModal from './components/Modal/BaseModal'

export default {
  data() {
    return {
      isMounted: false,
      isMaintain: window.location.search.substr(1),
      isPrerender : ( window.__prerender && window.__prerender.prerendering ) || false
    }
  },
  components: { NavBar, BFooter, MaidModal, ImageViewer, SignInModal, LoadingSpinner, BaseModal },
  // computed: {
  //   blockScroll() {
  //     return this.$store.state.blockScroll;
  //   },
  // },
  async mounted() {
    if(this.isPrerender){
      this.$nextTick(() => {
        setTimeout(() => {
          document.dispatchEvent(new Event("render-done"));
        }, 1000*25);
      })
    } else {
      if (!this.$route.path.startsWith('/en') && !this.$route.path.startsWith('/th') && !(this.$route.path == '/')) {
        this.$router.push('/' + this.$i18n.locale + this.$route.fullPath)
      }
      if (!this.isMounted) {
        let g = location.href + "?openExternalBrowser=1";
        console.log("window::::", g);
  
        let UA = navigator.userAgent || navigator.vendor || window.opera;
  
        let isLINE = function (UA) {
          return UA.indexOf("Line/") > -1;
        };
        if (isLINE(UA)) {
          // alert("from Line");
          // window.open(g);
          location.href = g;
        }
        this.isMounted = true;
      }
      console.log("app", window.location.search.substr(1));
    
    }
  },
};
</script>
<style lang="scss" scoped>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Kanit", sans-serif;
}

.blockScroll {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.disable-scrolling{
  height: 100%;
  overflow-x: hidden;
}

.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(90deg,#000 30%,#0005,#000 50%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

.shimmer-background {
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

</style>
