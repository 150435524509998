import { getSendbirdConfig as getSbConfig, getEnvName } from '../helpers/network'
const host = window.location.host
const env = getEnvName(host)

export const APP_ID = 'AC287AD4-E4DC-4CD2-B9F5-9E5AB205C294'
export const ACCESS_TOKEN = 'e4224011e0a78d002c63e296ae13dfd3c7e222f1'
export const ADMIN_ID = 'Admin'
export const IMAGE_TYPE = ['image/jpeg', 'image/png']
export const VIDEOS_TYPE = ['video/mp4', 'video/mp3']

export const getSendbirdConfig = () => {
  return getSbConfig(env)
}
